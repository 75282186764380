import React, { useState } from "react";
import AddCustomerDetails from "./AddCustomerDetails";

export default function () {


    return (
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="card">
                    <div className="card-header card-header-tabs card-header-primary">
                    <div className="d-flex">
                            <span className="nav-tabs-title">SACCO CUSTOMERS</span>
                        </div>
                        <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                                <span className="nav-tabs-title">Tasks:</span>
                                <ul className="nav nav-tabs" data-tabs="tabs">
                                    <li className="nav-item">
                                        <button className="nav-link active"  data-toggle="tab">
                                            Add Customer
                                            <div className="ripple-container"></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <AddCustomerDetails />   
                </div>
            </div>
        </div>

    )
}