import React, { useState, useContext } from "react";
import AuthContext from "../../contex/AuthProvider";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.jpg";
import { Link } from 'react-router-dom'

import axios from "../../api/axios";
const LOGIN_URL = 'admin/login'

export default function (props) {

    const { setAuth } = useContext(AuthContext)

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [spinner, setSpinier] = useState('');

    //set error messages
    const [errEmail, setErrEmailMsg] = useState('');
    const [errPassword, setErrPasswordMsg] = useState('');
    const [formError, setFormError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinier('fa fa-spinner fa-spin');

        if (email === '') {
            setErrEmailMsg('Email address is required');
        } else {
            setErrEmailMsg('')
        }

        if (password === '') {
            setErrPasswordMsg('Password is required');
        } else {
            setErrPasswordMsg('')
        }

        if (email !== '' && password !== '') {

            try {
                const headers = {
                    "Content-Type": "application/json"
                };
                
                const response = await axios.post(LOGIN_URL,
                    JSON.stringify({
                        email:email, password:password
                    }),
                    {
                        headers
                    }
                );

                localStorage.setItem('MilkCardSmsEmail', response.data.data[0].email);
                localStorage.setItem('MilkCardSmsFirstName', response.data.data[0].firstName);
                localStorage.setItem('MilkCardSmsSacco', response.data.data[0].dairySacco);
                localStorage.setItem('MilkCardSmsSaccoBranch', response.data.data[0].saccoBranch);
                localStorage.setItem('MilkCardSmsBranchId', response.data.data[0].branchId);
                localStorage.setItem('MilkCardSmsDairySaccoCode', response.data.data[0].dairySaccoCode);
                localStorage.setItem('MilkCardSmsTokenKey', response.data.data[0].tokenKey);
                localStorage.setItem('MilkCardSmsSessionId', response.data.data[0].sessionID);

                setSpinier('');
                navigate('/dashboard/milkCollection');

            } catch (error) {
                setSpinier('');
                setFormError(error.response.data.message)
            }

        } else { setSpinier(''); }
    }


    return (
        <section className="container-fluid min-vh-100">
            <div className="d-sm-flex justify-content-center">
                <div className="col-sm-8 col-md-6 col-lg-3 p-5 bg-light mt-5 box-shadow rounded">
                    <form onSubmit={handleSubmit} id="send-message" className="form-padd">
                        <div className="text-center mb-4">
                            <img className="mb-1" src={logo} alt="" width="150" height="121" />
                            <h1 className="century-600">MilkCardSms</h1>
                            <p className="h3 lato-600">Login</p>
                            <small className="my-error">{formError}</small>
                        </div>
                        <div className="mb-3">
                            <label className="lato" htmlFor="full-name">Email</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control form-control-lg border border-dark lato input-p" id="full-name" placeholder="Enter your email adress" />
                            <small className="my-error">{errEmail}</small>
                        </div>
                        <div className="mb-3">
                            <label className="lato" htmlFor="user-email">Password</label>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control form-control-lg border border-dark lato input-p" id="user-email" placeholder="Enter your password" />
                            <small className="my-error">{errPassword}</small>
                        </div>
                        <div className="d-flex mt-3">
                            <button type="submit" className="w-50 btn-submit border-0 py-3 fw-bolder mx-auto brown text-white lato">
                                Login&nbsp;<i className={spinner}></i>
                            </button>
                        </div>
                        <Link className="nav-link" to="/forgotPassword">
                            <p className="text-center mt-3 blue">Forgot Password</p>
                        </Link>
                    </form>
                </div>
            </div>

        </section>
    )
}