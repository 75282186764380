import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardSearchResult from './CardSearchResult'
import axios from "axios";



const CardSearchMilkRecord = ({ dairyBranches }) => {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [components, setComponents] = useState([]); 

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [saccoBranchPoint, setPoint] = useState(getInitialStateBranch);
    const [date, setDate] = useState('');
    const [recordType, setRecordType] = useState(getInitialStateBranch);
    const [recordState, setRecordState] = useState(getInitialStateBranch);

    //set error messages
    const [errSaccoPoint, setErrSaccoBranchMsg] = useState('');
    const [errRecordType, setErrRecordTypeMsg] = useState('');
    const [errRecordState, setErrRecordStateMsg] = useState('');
    const [errDate, setErrDate] = useState('');

    useEffect(() => {
        setErrSaccoBranchMsg('');
    }, [saccoBranchPoint]);

    useEffect(() => {
        setErrRecordTypeMsg('');
    }, [recordType]);

    useEffect(() => {
        setErrRecordStateMsg('');
    }, [recordState]);
    useEffect(() => {
        setErrDate('');
    }, [date]);

    const onBranchChange = (e) => {
        setPoint(e.target.value);
    };

    const onRecordChange = (e) => {
        setRecordType(e.target.value);
    };

    const onRecordState = (e) => {
        setRecordState(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (saccoBranchPoint === "select") {
            setErrSaccoBranchMsg("Select a dairy Branch");
        } else {
            setErrSaccoBranchMsg("");
        }
        if (recordType === "select") {
            setErrRecordTypeMsg("Select a record Type")
        } else {
            setErrRecordTypeMsg("");
        }
        if (recordState === "select") {
            setErrRecordStateMsg("Select a record state")
        } else {
            setErrRecordStateMsg("");
        }
        if(date === ""){
            setErrDate("Record date is required");
        } else {
            setErrDate("");
        }
        console.log(saccoBranchPoint, date, recordType, recordState);


        //milk record search

        if(saccoBranchPoint !== "select" && date !== "" && recordType !== "select" && recordState !== "select"){

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/DairySales/searchSaleRecord";

        const headers = {
            'Authorization': `Bearer ${sessionID}`,
            "Content-Type": "application/json"
        };

        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: tokenKey,
            saccoBranch: saccoBranchPoint,
            searchDate: date,
            recordType: recordType,
            recordState: recordState,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                console.log(response.data);
                setComponents(<CardSearchResult search={[response.data, saccoBranchPoint, date, recordType, recordState]}/>)

                // setPoint(getInitialStateBranch);
                // setRecordType(getInitialStateBranch);
                // setRecordState(getInitialStateBranch);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log(error);
            })
            .then(() => {});
    }

    }
    return (
        <div>
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-tabs card-header-primary">
                            <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                    <span className="nav-tabs-title">Search Dairy Sale</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="tab-pane active" id="profile">
                                    <form onSubmit={handleSubmit} id="Search-form">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="points" className="card-category">Sale Point</label>
                                                    <select value={saccoBranchPoint} id="points" name="Sacco-Points" className="form-control" onChange={onBranchChange}>
                                                        <option value="select">Select Dairy Branch</option>
                                                        <option value="000111222333">All</option>
                                                        {
                                                            dairyBranches.map((branch, i) => (
                                                                <option key={i} value={branch.branchId}>{branch.saccoBranch}</option>
                                                            ))
                                                            }
                                                    </select>
                                                    <small className="my-error">{errSaccoPoint}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="date" className="card-category">Search Date</label>
                                                    <input id="date" type="date" name="Search-Milk-Collection-Date" className="form-control" onChange={(e) => setDate(e.target.value)}/>
                                                    <small className="my-error">{errDate}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="type" className="card-category">Record Type</label>
                                                    <select value={recordType} id="type" name="Record-Type" className="form-control" onChange={onRecordChange}>
                                                        <option value="select">Select Record Type</option>
                                                        <option value="Sales">Dairy Sales</option>
                                                        <option value="Particular">Dairy Particular Sales</option>
                                                    </select>
                                                    <small className="my-error">{errRecordType}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="state" className="card-category">Record State</label>
                                                    <select value={recordState} id="state" name="Record-State" className="form-control" onChange={onRecordState}>
                                                        <option value="select">Select Record State</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Disabled</option>
                                                    </select>
                                                    <small className="my-error">{errRecordState}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <button id="ButtonSearchMilkCollection" type="submit" className="btn btn-sm btn-primary"
                                            name="Button-Search-Milk-Collection"><i id="searchCollection"></i> Search Milk collection</button>
                                        <div className="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="search">
            {components}
            </div>
        </div>

    )
}

export default CardSearchMilkRecord