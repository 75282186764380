import React from "react";
import LoginForm from "../components/authentication/LoginForm";

const Login = () => {

    return (
    <div className="wrapper ">
        <LoginForm />
    </div>
    )
}

export default Login