import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/Footer";
import TabEmployee from "../components/manageDairy/TabEmployee";
import TabDairyProducts from "../components/manageDairy/TabDairyProducts";
import TabAnimalBreeds from "../components/manageDairy/TabAnimalBreeds";
import TabDairySaccoPoints from "../components/manageDairy/TabDairySaccoPoints";
import TabFarmer from "../components/manageDairy/TabFarmer";
import TabSaccoSettings from "../components/manageDairy/TabSaccoSettings";
import TabSaccoCustomers from "../components/manageDairy/TabSaccoCustomers";
import SpinnerLoader from "../components/loader/SpinnerLoader";

import axios from "axios";

const ManageDairySacco = () => {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [isLoading, setLoading] = useState(true);

    const [components, setComponents] = useState([]);

    const [dairyPoints, setDairyPoints] = React.useState([]);

    var saccoDairyPoint = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/dairyPoints";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(saccoDairyPoint, {
            sacco: sacco,
            email: email,
            token: tokenKey,
            saccoBranchId: saccoBranchId,
            pointType: "AllPoint"
        }, { headers })
            .then(function (response) {
                setDairyPoints(response.data.data);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                navigate('/');
            }
            });

    }, []);

    const pageButtonsClick = (page) => {
        if (page === "ManageEmployees") {
            setComponents(<TabEmployee dairyPoints={dairyPoints} />);
        } else if (page === "DairySaccoProducts") {
            setComponents(<TabDairyProducts />);
        } else if (page === "ArtificialBreeds") {
            setComponents(<TabAnimalBreeds />);
        } else if (page === "DairySaccoPoints") {
            setComponents(<TabDairySaccoPoints dairyPoints={dairyPoints} />);
        } else if (page === "FarmerDetails") {
            setComponents(<TabFarmer dairyPoints={dairyPoints} />);
        } else if (page === "SaccoSettings") {
            setComponents(<TabSaccoSettings />);
        } else if (page === "SaccoCustomers") {
            setComponents(<TabSaccoCustomers />);
        }
    }

    if(isLoading){
        return (<SpinnerLoader page="manageSacco" />)
    }

    return (
        <div className="wrapper ">
            <SideBar pageActive={"manageSacco"} />
            <div className="main-panel">
                <NavBar page={["Milk Collection", firstName]} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="card card-plain">
                            <div className="card">
                                <div className="card-header card-header-primary">
                                    <h4 className="card-title"> Loitokitok Dairy Sacco</h4>
                                    <p className="card-category">
                                        You can manage dairy sacco from this page
                                    </p>
                                </div>
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="places-buttons">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-10 ml-auto mr-auto">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <button
                                                                className="btn btn-primary btn-block btn-1"
                                                                onClick={() => pageButtonsClick("ManageEmployees")}
                                                            >
                                                                Manage Employee
                                                            </button>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button
                                                                className="btn btn-primary btn-block btn-2"
                                                                onClick={() => pageButtonsClick("DairySaccoProducts")}
                                                            >
                                                                Dairy Products
                                                            </button>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button
                                                                className="btn btn-primary btn-block btn-3"
                                                                onClick={() => pageButtonsClick("ArtificialBreeds")}
                                                            >
                                                                Artificial Insemination
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <button
                                                                className="btn btn-primary btn-block"
                                                                onClick={() => pageButtonsClick("DairySaccoPoints")}
                                                            >
                                                                Dairy Sacco Points
                                                            </button>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button
                                                                className="btn btn-primary btn-block btn-4"
                                                                onClick={() => pageButtonsClick("FarmerDetails")}
                                                            >
                                                                Farmers Details
                                                            </button>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button
                                                                className="btn btn-primary btn-block brown"
                                                                onClick={() => pageButtonsClick("SaccoSettings")}
                                                            >
                                                                Sacco settings
                                                            </button>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button
                                                                className="btn btn-primary btn-block btn-5"
                                                                onClick={() => pageButtonsClick("SaccoCustomers")}
                                                            >
                                                                Sacco Customers
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {components}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default ManageDairySacco;
