import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from "../images/logo.jpg";
import axios from "axios";
import Swal from 'sweetalert2';

export default function () {

    const navigate = useNavigate();

    const search = useLocation().search;
    const email = new URLSearchParams(search).get("Email");

    const [spinner, setSpinier] = useState('');
    

    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //set error messages
    const [errCode, setErrCodeMsg] = useState('');
    const [errPass, setErrPassMsg] = useState('');
    const [errConf, setErrConfMsg] = useState('');

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinier('fa fa-spinner fa-spin');

        if (code === '') {
            setErrCodeMsg('Single-use code is required');
        } else {
            setErrCodeMsg('')
        }

        if (password === '') {
            setErrPassMsg('Password is required');
        } else {
            setErrPassMsg('')
        }

        if (confirmPassword === '') {
            setErrConfMsg('Confirm password is required');
        } else {
            setErrConfMsg('')
        }

        if(password !== confirmPassword){
            setFormError('password do not match');
            setError('error-block')
        }

        if (code !== "" && email !== "" && password !== "" && confirmPassword !== "" && password === confirmPassword) {

            var loginUrl = "https://debug.martinnganganjoroge.com/gateway/admin/Request/admin/resetUserPassword";

            const headers = {};

            axios.post(loginUrl, {
                email: email,
                code: code,
                password: password
            }, { headers })
                .then(function (response) {
                    setSpinier('');
                    setFormError(response.data.data[0]['message']);
                    setError('success-block');
                    //console.log(response.data.data[0]['message']);
                    Swal.fire({
                        text: response.data.data[0]['message'],
                        timer: 2000,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            navigate('/');
                        }
                      });
                })
                .catch(function (error) {
                    setSpinier('');
                    console.log("Catch : " + error);
                        console.log("Catch : " + error.response.status);
                        console.log(error.response.data);
                        setFormError(error.response.data.message);
                        setError('error-block');
                });

        } else { setSpinier(''); }
    }

    return (
        <section className="container-fluid min-vh-100">
            <div className="d-sm-flex justify-content-center">
                <div className="col-sm-8 col-md-6 col-lg-3 p-5 bg-light mt-5 box-shadow rounded">
                    <form onSubmit={handleSubmit} id="send-message" className="form-padd">
                        <div className="text-center mb-4">
                            <img className="mb-1" src={logo} alt="" width="150" height="121" />
                            <h1 className="century-600">MilkCardSms</h1>
                            <p className="h3 lato-600">Enter code</p>
                            <small for="ToolPick" className={error}>{formError}</small>
                        </div>
                        <div className="mb-3">
                            <label className="lato" htmlFor="forgot-code">We emailed a code to <b>{email}</b>. Please enter the code to continue.</label>
                            <input value={code} onChange={(e) => setCode(e.target.value)} type="number" className="form-control form-control-lg border border-dark lato input-p" id="forgot-code" placeholder="Enter Code" autoComplete="off"/>
                            <small className="my-error">{errCode}</small>
                        </div>
                        <div className="mb-3">
                            <label className="lato" htmlFor="password">Password</label>
                            <input minLength={8} value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control form-control-lg border border-dark lato input-p" id="password" placeholder="Enter your password" />
                            <small className="my-error">{errPass}</small>
                        </div>
                        <div className="mb-3">
                            <label className="lato" htmlFor="conf-password">Confirm Password</label>
                            <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control form-control-lg border border-dark lato input-p" id="conf-password" placeholder="Confirm your password" />
                            <small className="my-error">{errConf}</small>
                        </div>
                        <div className="d-flex mt-3">
                            <button type="submit" className="w-50 btn-submit border-0 py-3 fw-bolder mx-auto brown text-white aileron">
                                <div id="spinner-msg" className="spinner-border spinner-border-sm" role="status" style={{ display: 'none' }}>&ensp;</div>
                                Reset Password&nbsp;<i className={spinner}></i>
                            </button>
                        </div>
                        <Link className="nav-link" to="/">
                        <p className="text-center mt-3 blue">Login</p>
                        </Link>
                    </form>
                </div>
            </div>
        </section>
    )
}