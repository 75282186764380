import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import CardBrown from "../components/card/CardBrown";
import CardYellow from "../components/card/CardYellow";
import CardBrownLong from "../components/card/CardBrownLong";
import CardYellowLong from "../components/card/CardYellowLong";
import CardSearchSaccoSale from "../components/card/CardSearchSaccoSale";
import Footer from "../components/footer/Footer";
import SpinnerLoader from "../components/loader/SpinnerLoader";

import axios from "axios";


const DairySales = () => {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [salesStats, setSalesStats] = useState([]);

    var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/DairySales/dairySalesStats";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: tokenKey,
            saccoBranchId: saccoBranchId
          }, { headers })
          .then(function (response) {
            setSalesStats(response.data);
            console.log(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
                navigate('/');
            }
          });
        
    }, []);


    if (salesStats.length < 1) {
        return (
            <SpinnerLoader page="saccoSale" />
        )
    }

    return (

        <div className="wrapper ">
            <SideBar pageActive={"saccoSale"}/>
            <div className="main-panel">
                <NavBar page={["Dairy Sales", firstName]}/>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <CardBrown brownData={["Today's Dairy Sales", "Sales in Shillings", salesStats.data[0].todaySales, "DairySales"]} />
                            <CardYellow yellowData={["Month's Dairy Sales", "Sales in Shillings", salesStats.data[0].monthSales, "DairySales"]}/>
                        </div>
                        <div className="row">
                            <CardBrownLong brownLongData={["Today's Particular Sales", "Sales in shillings", salesStats.data[0].todayParticularSales, "Particulars"]}/>

                            <CardYellowLong yellowLongData={["Months's Particular Sales", "Sales in shillings", salesStats.data[0].monthsParticularSales, "Particulars"]}/>
                        </div>
                        <CardSearchSaccoSale dairyBranches={salesStats.saccoBranches} />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default DairySales