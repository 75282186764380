import React, { useState, useEffect } from "react";
import LinkDairySalesTable from "../viewRecords/LinkDairySalesTable";
import LinkMilkCollectionMonthTable from "../viewRecords/LinkMilkCollectionMonthTable";

export default function ({yellowData}) {

    const [components, setComponents] = useState();

    useEffect(()=>{
        if(yellowData[3] === "MilkCollection"){
                setComponents(<LinkMilkCollectionMonthTable />)
        } else if (yellowData[3] === "DairySales"){
            setComponents(<LinkDairySalesTable />);
        }
    });

    return (
        <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="card card-stats">
                <div className="card-header card-header-success card-header-icon">
                    <div className="card-icon">
                    </div>
                    <h4 className="card-title">{yellowData[0]}</h4>
                    <p className="card-category">{yellowData[1]}</p>
                    <h3 className="card-title">{yellowData[2]}</h3>
                </div>
                <div className="card-footer">
                    <div className="stats">
                    {components}
                        {/* <button type="submit" className="btn btn-sm btn-primary green"
                            id="MonthAIExcel"><i id="imonthAiExcel"></i> Download Excel file</button> */}
                    </div>
                </div>
            </div>
        </div>

    )
}