import React from "react";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import SaccoSalesRecordTable from "../components/tables/SaccoSalesRecordTable"

const SaleRecordTable = () => {

    const firstName = localStorage.getItem("MilkCardSmsFirstName");

    return (
    <div className="wrapper ">
        <SideBar pageActive={"saccoSale"} />
        <div className="main-panel">
            <NavBar page={["Dairy Sales", firstName]}/>
            <div className="content">
                <div className="container-fluid">
                    <SaccoSalesRecordTable />
                </div>
            </div>
        </div>
    </div>
    )
}

export default SaleRecordTable