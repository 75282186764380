import React from "react";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import ReportTableMilkRecord from "../components/tables/ReportTableMilkRecord"

const ReportMilkCollection = () => {

    return (
    <div className="wrapper ">
        <SideBar pageActive={"farmersMilkReport"} />
        <div className="main-panel">
            <NavBar page={["Farmers Milk Collection Report", "Martin"]}/>
            <div className="content">
                <div className="container-fluid">
                    <ReportTableMilkRecord />
                </div>
            </div>
        </div>
    </div>
    )
}

export default ReportMilkCollection