import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import axios from "axios";
import Swal from 'sweetalert2';

export default function ({ dairyPoints }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const sessionEmail = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [style, setStyle] = useState('modal');

    const [isLoading, setLoading] = useState(true);
    const [employees, setEmployees] = React.useState([]);

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error')

    const [firstName, setFirstName] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const [portalAccess, setPortalAccess] = useState(false);
    const [viewRecords, setViewRecords] = useState(false);
    const [downloadRecords, setDownloadRecords] = useState(false);
    const [manageDairySacco, setManageDairySacco] = useState(false);
    const [updateFarmerRecords, setUpdateFarmerRecords] = useState(false);
    const [appAccess, setAccessApp] = useState(false);

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [saccoBranchPoint, setPoint] = useState(getInitialStateBranch);

    const onBranchChange = (e) => {
        setPoint(e.target.value);
    };

    const accessPortal = async (e) => {

        if (e.target.checked === true) {
            setPortalAccess(true);
        } else {
            setPortalAccess(false);
        }
    };

    const viewRecordsPortal = async (e) => {
        if (e.target.checked === true) {
            setViewRecords(true);
        } else {
            setViewRecords(false);
        }
    }

    const downloadRecordsPortal = async (e) => {
        if (e.target.checked === true) {
            setDownloadRecords(true);
        } else {
            setDownloadRecords(false);
        }
    }

    const manageDairySaccoPortal = async (e) => {
        if (e.target.checked === true) {
            setManageDairySacco(true);
        } else {
            setManageDairySacco(false);
        }
    }

    const updateFarmerRecordsPortal = async (e) => {
        if (e.target.checked === true) {
            setUpdateFarmerRecords(true);
        } else {
            setUpdateFarmerRecords(false);
        }
    }

    const accessApp = async (e) => {
        if (e.target.checked == true) {
            setAccessApp(true);
        } else {
            setAccessApp(false);
        }
    }

    var saccoDairyPoint = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/getSaccoEmployees";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(saccoDairyPoint, {
            sacco: sacco,
            email: sessionEmail,
            token: tokenKey,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setEmployees(response.data.data);
                console.log(response.data.data);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
            });

    }, []);

    const editEmployeeRecord = (rowData) => {

        setFirstName(rowData.firstName);
        setOtherNames(rowData.otherNames);
        setPhoneNumber(rowData.phoneNumber);
        setPoint(rowData.branchId);
        setEmail(rowData.email);
        setPortalAccess(rowData.portalAccess);
        setViewRecords(rowData.viewRecords);
        setDownloadRecords(rowData.downloadRecords);
        setManageDairySacco(rowData.manageDairySacco);
        setUpdateFarmerRecords(rowData.updateFarmerRecords);
        setAccessApp(rowData.accessApp);

        setStyle('modal-open');
    }

    const disableEmployeeRecord = (e, rowData) => {

        e.preventDefault();

       
        Swal.fire({
            title: '<strong>Disable Employee</strong>',
            icon: 'info',
            html:
                '<b>Do you wish to  disable employee details of</b><br />, ' +
                'Employee Name ' + rowData.firstName + " " + rowData.otherNames +
                '<br />Email: ' + rowData.email +
                '<br />Phone Number: ' + rowData.phoneNumber,
            showCancelButton: true,
            confirmButtonColor: '#ec4b48',
            cancelButtonColor: '#808080',
            confirmButtonText: 'Disable'
        }).then((result) => {
            if (result.isConfirmed) {
                var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/disableEmployee";
        
        
                axios.post(filterURL, {
                    empEmail: rowData.email,  
                    email: sessionEmail,
                    token: tokenKey,  
                    saccoBranchId: saccoBranchId   
        
                }, { headers })
                    .then(function (response) {
                        Swal.fire(
                            response.data.status,
                            response.data.data[0].msg,
                            'success'
                        )

                        setTimeout(function () {
                            window.location.reload(false);
                        }, 2000);

                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            navigate('/');
                        }
                        console.log("Catch : " + error);
                        console.log("Catch : " + error.response.status);
                        console.log(error.response.data);
                        setFormError(error.response.data.message)
                        setError('error-block')
        
                    });
            }
        });
    }

    const column = [
        { title: "First Name", field: "firstName" },
        { title: "Other Names", field: "otherNames" },
        { title: "Phone Number", field: "phoneNumber" },
        { title: "Dairy Point", field: "saccoBranch" },
        { title: "BranchId", field: "branchId", hidden: true },
        { title: "Email", field: "email" },
        { title: "portalAccess", field: "portalAccess", hidden: true },
        { title: "portalViewRecords", field: "viewRecords", hidden: true },
        { title: "portalDownloadRecords", field: "downloadRecords", hidden: true },
        { title: "portalManageDairySacco", field: "manageDairySacco", hidden: true },
        { title: "portalUpdateFarmerRecords", field: "updateFarmerRecords", hidden: true },
        { title: "appAccess", field: "appAccess", hidden: true },
        {
            field: "url",
            title: "Action",
            render: (rowData) => {
                return (
                    <div>
                        <button title="Edit Record" onClick={() => editEmployeeRecord(rowData)} className='btn btn-warning'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path>
                            </svg>
                        </button>
                        <button title="Disable Record" onClick={(e) => disableEmployeeRecord(e, rowData)} className="btn btn-primary btn-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"></path>
                            </svg>
                        </button>
                    </div>
                );
            },
        },
    ];

    const closeModal = () => {
        setStyle('modal')
    }

    const handleSubmit = async (e) => {
        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/updateEmployee";


        axios.post(filterURL, {
            userEmail: email,
            phoneNumber: phoneNumber,
            dairyPoint: saccoBranchPoint,
            portalAccess: portalAccess,
            portalViewRecords: viewRecords,
            portalDownloadRecords: downloadRecords,
            portalManageDairySacco: manageDairySacco,
            portalUpdateFarmerRecords: updateFarmerRecords,
            appAccess: appAccess,
            email: sessionEmail,
            token: tokenKey,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setFirstName('');
                setOtherNames('');
                setPhoneNumber('');
                setEmail('');
                setPoint(getInitialStateBranch);
                setPortalAccess(false);
                setViewRecords(false);
                setDownloadRecords(false);
                setManageDairySacco(false);
                setUpdateFarmerRecords(false);
                setAccessApp(false);
                Swal.fire(
                    response.data.status,
                    response.data.data[0].msg,
                    'success'
                )
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log("Catch : " + error);
                console.log("Catch : " + error.response.status);
                console.log(error.response.data);
                setFormError(error.response.data.message)
                setError('error-block')

            });
    }

    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="card-body">

            <div id="myModal" className={style}>
                <div className="modal-content">
                    <span className="close" onClick={() => closeModal()}>&times;</span>
                    <div className="card">
                        <div className="card-header card-header-primary">
                            <h4 className="card-title ">Update Employee Records</h4>
                            <p className="card-category">You can update or Disable Employee records from this window</p>
                        </div>
                        <div className="card-body">
                            <p for="ToolPick" className={error}>{formError}</p>
                            <form onSubmit={handleSubmit} id="FarmerCollectedMilkUpdate">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="firstName" className="card-category">First Name(Read Only)</label>
                                        <input value={firstName} type="text" className="form-control" id="firstName" readonly />
                                        <label htmlFor="otherNames" className="card-category">Other Names (Read Only)</label>
                                        <input value={otherNames} type="text" className="form-control" id="otherNames" readonly />
                                        <label htmlFor="phoneNumber" className="card-category">Phone Number</label>
                                        <input value={phoneNumber} type="text" className="form-control" id="phoneNumber" onChange={(e) => setPhoneNumber(e.target.value)} />
                                        <label htmlFor="dairyPoint" className="card-category">Sacco Branch</label>
                                        <select id="dairyPoint" className="form-control" value={saccoBranchPoint} onChange={onBranchChange}>
                                            <option value="select">Select Dairy Branch</option>
                                            {
                                                dairyPoints.map((points, i) => (
                                                    <option key={i} value={points.branchId}>{points.saccoBranch}</option>
                                                ))
                                            }
                                        </select>

                                        <label htmlFor="email" className="card-category">Email (Read Only)</label>
                                        <input value={email} type="text" className="form-control" id="MilkSaccoBranch" readonly />
                                        <button type="submit" id="RecordUpdate" className="btn btn-primary"><i id="iRecordUpdate"></i> Update Record</button>
                                        <span className="btn btn-primary btn-5" onClick={() => closeModal()}>Cancel</span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <p id="Dairy-PointError" className="bmd-label-floating">Roles Portal</p>
                                            <div title="User can only view sacco statistics">
                                                <input className="roles-input" type="checkbox" id="accessPortal" value={portalAccess} checked={portalAccess} onChange={accessPortal} />
                                                <label htmlFor="accessPortal">Access Portal</label>
                                            </div>
                                            <div title="User View sacco records">
                                                <input className="roles-input" type="checkbox" id="viewRecords" value={viewRecords} checked={viewRecords} onChange={viewRecordsPortal} />
                                                <label htmlFor="viewRecords">View Records</label>
                                            </div>
                                            <div title="User can download sacco records">
                                                <input className="roles-input" type="checkbox" id="downloadRecords" checked={downloadRecords} onChange={downloadRecordsPortal} />
                                                <label htmlFor="downloadRecords">Download Records</label>
                                            </div>
                                            <div title="User can add or Update employee rcords, products records, dairy Point, and farmers details">
                                                <input className="roles-input" type="checkbox" id="manageDairySacco" checked={manageDairySacco} onChange={manageDairySaccoPortal} />
                                                <label htmlFor="manageDairySacco">Manage Dairy Sacco</label>
                                            </div>
                                            <div title="user can update famer records">
                                                <input className="roles-input" type="checkbox" id="updateFarmerRecords" checked={updateFarmerRecords} onChange={updateFarmerRecordsPortal} title="user can update famer records" />
                                                <label htmlFor="UpdateFarmerRecords">Update Farmer's Records</label>
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <p id="Dairy-PointError" className="bmd-label-floating">Roles App</p>

                                            <input className="roles-input" type="checkbox" id="accessApp" checked={appAccess} onChange={accessApp} />
                                            <label htmlFor="accessApp">Access App</label><br />

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >



            <div className="tab-content">
                <div className="tab-pane active" id="messages">
                    <div className="card-body">
                        <p for="ToolPick" className="error"></p>
                        <form id="updateEmployee">
                            <div className="row">
                                <div className="col-md-12">
                                    <MaterialTable
                                        title=''
                                        data={employees}
                                        columns={column}
                                        options={{
                                            exportMenu: [
                                                {
                                                    label: "Export PDF",
                                                    exportFunc: (cols, datas) =>
                                                        ExportPdf(cols, datas, "search[2]"),
                                                },
                                                {
                                                    label: "Export CSV",
                                                    exportFunc: (cols, datas) =>
                                                        ExportCsv(cols, datas, "search[2]"),
                                                },
                                            ],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='clearfix'></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}