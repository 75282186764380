import React from "react";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import RejectedMilkRecordTable from "../components/tables/RejectedMilkRecordTable";

const RejectedMilkTable = () => {

    return (
    <div className="wrapper ">
        <SideBar pageActive={"milkCollection"} />
        <div className="main-panel">
            <NavBar page={["Milk Collection", "Martin"]}/>
            <div className="content">
                <div className="container-fluid">
                    <RejectedMilkRecordTable />
                </div>
            </div>
        </div>
    </div>
    )
}

export default RejectedMilkTable