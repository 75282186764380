import React from "react";
import { Link } from 'react-router-dom'

export default function (props) {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <nav className="float-left">
                    <ul>
                        <li>
                            <Link to="#">
                                MilkCardSms
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="copyright float-right">
                    &copy;
                    2022
                </div>
            </div>
        </footer>

    )
}