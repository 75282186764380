import React from "react";
import SideBar from "../sideBar/SideBar";
import NavBar from "../navBar/NavBar";

export default function ({ page }) {

    const firstName = localStorage.getItem("MilkCardSmsFirstName");

    return (
        <div className="wrapper ">
            <SideBar pageActive={page} />
            <div className="main-panel">
                <NavBar page={["Milk Collection", firstName]} />
                <div className="content d-flex justify-content-center">
                    <div className="loader my-auto"></div>
                </div>
            </div>
        </div>
    )
}