import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ dairyPoints }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");

    const [spinnerOpen, setSpinierOpen] = useState('');
    const [spinnerClose, setSpinierClose] = useState('');

    const [formError, setFormError] =  useState('');
    const [error, setError] = useState('error');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [saccoBranchPoint, setPoint] = useState(getInitialStateBranch);

    const onBranchChange = (e) => {
        setPoint(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    }

    const buttonClick = (btnClick) => { 

        if(buttonClick == 'Open'){
            setSpinierOpen('fa fa-spinner fa-spin');
        } else {
            setSpinierClose('fa fa-spinner fa-spin');
        }

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/closeDairyPoint";

        const headers = {
            'Authorization': `Bearer ${sessionID}`,
            "Content-Type": "application/json"
        };
       
        axios.post(filterURL, {
            dairyPointName: saccoBranchPoint,
            action: btnClick,
            email: email,
            token: token,
            saccoCode: sacco,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                if(buttonClick == 'Open'){
                    setSpinierOpen('');
                } else {
                    setSpinierClose('');
                }
                setPoint(getInitialStateBranch);

                Swal.fire(
                    response.data.status,
                    response.data.data[0].msg,
                    'success'
                    )

            })
            .catch(function (error) {
                if(buttonClick == 'Open'){
                    setSpinierOpen('');
                } else {
                    setSpinierClose('');
                }
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log("Catch : " + error);
                console.log("Catch : " + error.response.status);
                console.log(error.response.data);
                setFormError(error.response.data.message)
                setError('error-block')

            });
    }


    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="messages">
                    <div className="card-body">
                    <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="deleteDairyPoint">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <p id="SelectpointError" className="card-category">Select Item</p>
                                    <select id="Selectpoint" className="form-control" value={saccoBranchPoint} onChange={onBranchChange}>
                                    <option value="select">Select Dairy Branch</option>
                                        {
                                            dairyPoints.map((points, i) => (
                                                <option key={i} value={points.saccoBranch}>{points.saccoBranch}</option>
                                            ))
                                        }
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right btn-1 me-3" id="openPoint" onClick={() => buttonClick("Open")}>Open Dairy Point&nbsp;<i className={spinnerOpen}></i></button>
                            <button type="submit" className="btn btn-primary pull-right btn-4" id="closePoint" onClick={() => buttonClick("Close")}>Close Dairy Point&nbsp;<i className={spinnerClose}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}