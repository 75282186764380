import React, { useState } from "react";

import AddProducts from "./AddProducts";
import RemoveProducts from "./RemoveProducts";

export default function () {

    const [activeTab, setActiveTab] = useState(<AddProducts />);

    const [addTab, setAddTab] = useState('nav-link active');
    const [updateTab, setUpdateTab] = useState('nav-link');



    const handleTab = (tab) => {
        // update the state to tab2
        if (tab === "Add") {
            setAddTab('nav-link active');
            setUpdateTab('nav-link');
            setActiveTab(<AddProducts />);
        } else if (tab === "Remove") {
            setAddTab('nav-link');
            setUpdateTab('nav-link active')
            setActiveTab(<RemoveProducts />);
        }

    };


    return (
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="card">
                    <div className="card-header card-header-tabs card-header-primary">
                    <div className="d-flex">
                            <span className="nav-tabs-title">DAIRY PRODUCTS</span>
                        </div>
                        <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                                <span className="nav-tabs-title">Tasks:</span>
                                <ul className="nav nav-tabs" data-tabs="tabs">
                                    <li className="nav-item">
                                        <button className={addTab} onClick={() => handleTab("Add")} data-toggle="tab">
                                        Add Produts
                                            <div className="ripple-container"></div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={updateTab} onClick={() => handleTab("Remove")} data-toggle="tab">
                                        Remove Products
                                            <div className="ripple-container"></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
                                {activeTab}
                            
                </div>
            </div>
        </div>

    )
}