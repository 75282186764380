import React, { useState, useEffect } from "react";

import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ dairyPoints }) {

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [formError, setFormError] =  useState('');
    const [error, setError] = useState('error');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const capitalizeWord= (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
      }

    const [firstName, setFirstName] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [farmersNumber, setFarmerNumber] = useState('');
    const [sacco, setSacco] = useState(localStorage.getItem("MilkCardSmsDairySaccoCode"));
    const [saccoBranch, setSaccoBranch] = useState(getInitialStateBranch);

    const [spinner, setSpinier] = useState('');

    //set error messages
    const [errFarmersNumber, setErrFarmersNumberMsg] = useState('');

    const onBranchChange = (e) => {
        setSaccoBranch(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (farmersNumber === null || farmersNumber === '' || isNaN(farmersNumber)) {
            setErrFarmersNumberMsg('Farmer Number should be a number');
        } else {
            setErrFarmersNumberMsg('');
        



        console.log(firstName + " " + otherNames + " " + phoneNumber + " " + farmersNumber + " " + sacco + " " + saccoBranch);

        setSpinier('fa fa-spinner fa-spin');
        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addFarmerDetails";

        const headers = {
            'Authorization': `Bearer ${sessionID}`,
            "Content-Type": "application/json"
        };

            axios.post(filterURL, {
                firstName: capitalizeWord(firstName),
                otherNames: capitalizeWord(otherNames),
                phoneNumber: phoneNumber,
                farmersNumber: sacco+"-"+farmersNumber,
                saccoBranch : saccoBranch,
                sacco: sacco,
                email: email,
                token: token,
                saccoBranchId: saccoBranchId

            }, { headers })
                .then(function (response) {
                    setSpinier('');
                    setError('error')
                    setFirstName('');
                    setOtherNames('');
                    setPhoneNumber('');
                    setFarmerNumber('');
                    setSaccoBranch(getInitialStateBranch);
                    Swal.fire(
                        response.data.status,
                        response.data.data[0].msg,
                        'success'
                      );
                })
                .catch(function (error) {
                    setSpinier('');
                    console.log("Catch : " + error);
                    console.log("Catch : " + error.response.status);
                    console.log(error.response.data);
                    setFormError(error.response.data.message)
                    setError('error-block')

                });
            }
        
    }

    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                    <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="addFarmer">
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label id="FirstNameError" className="bmd-label-floating">First Name</label>
                                      <input type="text" className="form-control" id="FirstName" minlength="3" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label id="secondNameError" className="bmd-label-floating">Other Names</label>
                                      <input type="text" className="form-control"  id="secondName" minlength="3" value={otherNames} onChange={(e) => setOtherNames(e.target.value)} required />
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label id="PhoneNumberError" className="bmd-label-floating">PhoneNumber</label>
                                      <input type="text" className="form-control"  id="PhoneNumber" minlength="10" maxlength="13" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label id="FarNumberError" className="bmd-label-floating" pattern="[0-9]*">Farmers Number</label>
                                      <input type="text" className="form-control"  id="FarNumber" value={farmersNumber} onChange={(e) => setFarmerNumber(e.target.value)} required />
                                      <small className="my-error">{errFarmersNumber}</small>
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label id="SelectpointError" className="bmd-label-floating">Milk Delivery Point</label>
                                      <select id="Selectpoint" className="form-control" value={saccoBranch} onChange={onBranchChange}>
                                      <option value="select">Select Dairy Branch</option>
                                        {
                                            dairyPoints.map((points, i) => (
                                                <option key={i} value={points.branchId}>{points.saccoBranch}</option>
                                            ))
                                        }
                                  </select>
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label id="DairySaccoError" className="bmd-label-floating">Dairy Sacco Code(disabled)</label>
                                      <input type="text" className="form-control" value={sacco}  id="DairySacco" readonly />
                                  </div>
                              </div>
                          </div>
                          <button type="submit" className="btn btn-primary pull-right" id="AddFarmer">Add Farmer Details&nbsp;<i className={spinner}></i></button>
                          <div className="clearfix"></div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    )
}