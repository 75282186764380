import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ animalBreeds }) {

  const navigate = useNavigate();

  const capitalizeWord = (name) => {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
  }

  const sessionID = localStorage.getItem("MilkCardSmsSessionId");
  const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
  const token = localStorage.getItem("MilkCardSmsTokenKey");
  const email = localStorage.getItem("MilkCardSmsEmail");
  const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

  const [spinner, setSpinier] = useState('');

  const [formError, setFormError] = useState('');
  const [error, setError] = useState('error');

  const getInitialStateBranch = () => {
    const value = "select";
    return value;
  };

  const [farmerNumber, setFarmerNumber] = useState('');
  const [cowName, setCowName] = useState('');
  const [cowBreed, setCowBreed] = useState(getInitialStateBranch);
  const [inseminatedBreed, setInseminatedBreed] = useState(getInitialStateBranch);
  const [price, setPrice] = useState('');

  //set error messages
  const [errFarmersNumber, setErrFarmerNumberMsg] = useState('');
  const [errCowName, setErrCowNameMsg] = useState('');
  const [errCowBreed, setErrCowBreedMsg] = useState('');
  const [errInseminatedBreed, setErrInseminatedBreedMsg] = useState('');
  const [errPrice, setErrPriceMsg] = useState('');

  const onCowBreedChange = (e) => {
    setCowBreed(e.target.value);
  };

  const onInseminatedBreedChange = (e) => {
    setInseminatedBreed(e.target.value);
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (farmerNumber === '' || farmerNumber === null) {
      setErrFarmerNumberMsg('Farmers number is required');
    } else {
      setErrFarmerNumberMsg('');
    }

    if (cowName === '' || cowName === null) {
      setErrCowNameMsg('Cows name is required');
    } else {
      setErrCowNameMsg('');
    }

    if (cowBreed === '' || cowBreed === null || cowBreed === 'select') {
      setErrCowBreedMsg('Cows Breed is required');
    } else {
      setErrCowBreedMsg('');
    }

    if (inseminatedBreed === '' || inseminatedBreed === null || inseminatedBreed === 'select') {
      setErrInseminatedBreedMsg('Inseminated breed is required');
    } else {
      setErrInseminatedBreedMsg('');
    }

    if (price === '' || price === null || isNaN(price)) {
      setErrPriceMsg('Price is required');
    } else {
      setErrPriceMsg('');
    }

    if (farmerNumber !== '' && cowName !== '' && cowBreed !== '' && inseminatedBreed !== '' && cowBreed !== 'select' && inseminatedBreed !== 'select' && price !== '' && !isNaN(price)) {

      setSpinier('fa fa-spinner fa-spin');
      var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addFarmerInsemination";

      const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
      };

      axios.post(filterURL, {
        sacco: sacco,
        farmerNumber: farmerNumber.toUpperCase(),
        cowName: capitalizeWord(cowName),
        cowBreed: cowBreed,
        inseminationBreed: inseminatedBreed,
        price: price,
        email: email,
        token: token,
        saccoBranchId: saccoBranchId
      }, { headers })
        .then(function (response) {
          setSpinier('');
          setFarmerNumber('');
          setCowName('');
          setCowBreed(getInitialStateBranch);
          setInseminatedBreed(getInitialStateBranch);
          setPrice('');
          setError('error')
          Swal.fire(
            response.data.status,
            response.data.data[0].msg,
            'success'
          );
        })
        .catch(function (error) {
          setSpinier('');
          if (error.response.status === 401) {
            navigate('/');
          }
          console.log("Catch : " + error);
          console.log("Catch : " + error.response.status);
          console.log(error.response.data);
          setFormError(error.response.data.message)
          setError('error-block')

        });

    }
  }

  return (
    <div className="card-body">
      <div className="tab-content">
        <div className="tab-pane active" id="profile">
          <div className="card-body">
            <p className={error}>{formError}</p>
            <form onSubmit={handleSubmit} id="addInsemination">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="farmerNumberError" className="bmd-label-floating">Farmer Number</label>
                    <input value={farmerNumber} onChange={(e) => setFarmerNumber(e.target.value)} type="text" className="form-control" id="farmerNumber" />
                    <small className="my-error">{errFarmersNumber}</small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="cowNameError" className="bmd-label-floating">Cow Name(Mother)</label>
                    <input value={cowName} onChange={(e) => setCowName(e.target.value)} type="text" className="form-control" id="cowName" />
                    <small className="my-error">{errCowName}</small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="cowBreedError" className="bmd-label-floating">Cow Breed(Mother)</label>
                    <select id="cowBreed" className="form-control" value={cowBreed} onChange={onCowBreedChange}>
                      <option value="1">Select Cow Breed</option>
                      <option value="Fresian/Holsteins">Fresian/Holsteins</option>
                      <option value="Holsteins_Red">Red and White Holsteins</option>
                      <option value="Aryshire">Aryshire</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Brown_Swiss">Brown Swiss</option>
                      <option value="Shorthorn">Milking Shorthorn</option>
                      <option value="Other">Other Breed</option>
                    </select>
                    <small className="my-error">{errCowBreed}</small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="SelectbreedError" className="bmd-label-floating">Inseminated Breed</label>
                    <select id="Selectbreed" className="form-control" value={inseminatedBreed} onChange={onInseminatedBreedChange}>
                      <option value="select">Select inseminated Breed</option>
                      {
                        animalBreeds.map((breed, i) => (
                          <option key={i} value={breed.breedId}>{breed.breedName}</option>
                        ))
                      }
                    </select>
                    <small className="my-error">{errInseminatedBreed}</small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label id="inseminationPriceError" className="bmd-label-floating">Insemination Price</label>
                    <input value={price} onChange={(e) => setPrice(e.target.value)} type="text" className="form-control" id="inseminationPrice" />
                    <small className="my-error">{errPrice}</small>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary pull-right" id="AddInseminationService">Add Insemination Service&nbsp;<i className={spinner}></i></button>
              <div className="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}