import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function () {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sessionSaccoCode = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    // const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    const [style, setStyle] = useState('modal');

    const [isLoading, setLoading] = useState(true);
    const [dairyPoints, setDairyPoints] = useState([]);

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const [firstName, setFirstName] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [milkcardNumber, setMilkCardNumber] = useState('');
    const [sacco, setSacco] = useState(false);

    const [spinner, setSpinier] = useState('');

    //set error messages
    const [errFarmersNumber, setErrFarmersNumberMsg] = useState('');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [saccoBranchPoint, setPoint] = useState(getInitialStateBranch);

    const closeModal = () => {
        setStyle('modal')
    }

    const onBranchChange = (e) => {
        setPoint(e.target.value);
    };

    const handleSubmitSearchFarmer = async (e) => {
        e.preventDefault();

        if (milkcardNumber === null || milkcardNumber === '' || isNaN(milkcardNumber)) {
            setErrFarmersNumberMsg('Farmer Number should be a number');
        } else {
            setErrFarmersNumberMsg('');

            setSpinier('fa fa-spinner fa-spin');

            var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/getFarmerDetails";


            axios.post(filterURL, {
                farmersNumber: sessionSaccoCode + "-" + milkcardNumber,
                sacco: sessionSaccoCode,
                email: email,
                token: tokenKey,
                saccoBranchId: saccoBranchId
            }, { headers })
                .then(function (response) {

                    setSpinier('');
                    console.log(response);
                    if (Object.keys(response.data.data).length === 0) {
                        setFormError("Farmer Number not found");
                        setError('error-block');
                    } else {
                        setFormError("");
                        setError('error');
                        setFirstName(response.data.data[0].firstName);
                        setOtherNames(response.data.data[0].otherNames);
                        setPhoneNumber(response.data.data[0].phoneNumber);
                        setMilkCardNumber(response.data.data[0].farmersNumber);
                        setSacco(response.data.data[0].dairySaccoCode);
                        setPoint(response.data.data[0].saccoBranch);

                        setStyle('modal-open');
                    }

                })
                .catch(function (error) {
                    setSpinier('');
                    if (error.response.status === 401) {
                        navigate('/');
                    }
                    console.log("Catch : " + error);
                    console.log("Catch : " + error.response.status);
                    console.log(error.response.data);
                    setFormError(error.response.data.message)
                    setError('error-block')

                });
        }
    }

    const buttonClick = (btnClick) => {
        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/updateFarmerDetails";

        axios.post(filterURL, {
            sacco: sessionSaccoCode,
            email: email,
            token: tokenKey,
            farmersNumber: milkcardNumber,
            saccoBranch: saccoBranchPoint,
            phoneNumber: phoneNumber,
            action: btnClick,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                console.log(response);
                setFirstName('');
                setOtherNames('');
                setPhoneNumber('');
                setMilkCardNumber('');
                setSacco('');
                setPoint(getInitialStateBranch);

                setStyle('modal');

                Swal.fire(
                    response.data.status,
                    response.data.data[0].msg,
                    'success'
                )

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log("Catch : " + error);
                console.log("Catch : " + error.response.status);
                console.log(error.response.data);
                setFormError(error.response.data.message)
                setError('error-block')

            });
    }

    const handleSubmitUpdateFarmer = async (e) => {

        e.preventDefault();

    }

    var saccoDairyPoint = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/dairyPoints";

    useEffect(() => {
        axios.post(saccoDairyPoint, {
            sacco: sessionSaccoCode,
            email: email,
            token: tokenKey,
            pointType: "MilkCollectionPoint",
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setDairyPoints(response.data.data);
                console.log(response.data.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    if (isLoading) {
        return <div></div>;
    }


    return (
        <div className="card-body">


            <div id="myModal" className={style}>
                <div className="modal-content">
                    <span className="close" onClick={() => closeModal()}>&times;</span>
                    <div className="card">
                        <div className="card-header card-header-primary">
                            <h4 className="card-title ">Update Farmer Records</h4>
                            <p className="card-category">You can update or Disable Farmer records from this window</p>
                        </div>
                        <div className="card-body">
                            <p for="ToolPick" className={error}>{formError}</p>
                            <form onSubmit={handleSubmitUpdateFarmer} id="FarmerCollectedMilkUpdate">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="firstName" className="card-category">First Name(Read Only)</label>
                                        <input value={firstName} type="text" className="form-control" id="firstName" readonly />
                                        <label htmlFor="phoneNumber" className="card-category">Phone Number</label>
                                        <input value={phoneNumber} type="text" className="form-control" id="phoneNumber" onChange={(e) => setPhoneNumber(e.target.value)} />
                                        <label htmlFor="email" className="card-category">Milk Card Number</label>
                                        <input value={milkcardNumber} type="text" className="form-control" id="MilkSaccoBranch" readonly />
                                        <label htmlFor="sacco" className="card-category">Sacco</label>
                                        <input value={sacco} type="text" className="form-control" id="sacco" readonly />
                                        <label htmlFor="dairyPoint" className="card-category">Sacco Branch</label>
                                        <select id="dairyPoint" className="form-control" value={saccoBranchPoint} onChange={onBranchChange}>
                                            <option value="select">Select Dairy Branch</option>
                                            {
                                                dairyPoints.map((points, i) => (
                                                    <option key={i} value={points.branchId}>{points.saccoBranch}</option>
                                                ))
                                            }
                                        </select>
                                        <button type="submit" id="RecordUpdate" className="btn btn-sm btn-primary me-3" onClick={() => buttonClick("Update")}><i id="iRecordUpdate"></i> Update Record</button>
                                        <button type="submit" id="DisableRecord" className="btn btn-sm btn-primary btn-4 me-3" onClick={() => buttonClick("Disable")}><i id="iRecordUpdate"></i> Disable Records</button>
                                        <span className="btn btn-sm btn-primary" onClick={() => closeModal()}>Cancel</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="otherNames" className="card-category">Other Names (Read Only)</label>
                                        <input value={otherNames} type="text" className="form-control" id="otherNames" readonly />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >


            <div className="tab-content">
                <div className="tab-pane active" id="messages">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmitSearchFarmer} id="SearchFarmerNumber">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label id="farmerNumberError" class="bmd-label-floating">Farmers Number</label>
                                        <input type="text" class="form-control" id="farmerNumber" onChange={(e) => setMilkCardNumber(e.target.value)} />
                                        <small className="my-error">{errFarmersNumber}</small>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary pull-right" id="searchFarmer">Search&nbsp;<i className={spinner}></i></button>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}