import React from "react";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import MilkRecordTable from "../components/tables/MilkRecordTable"

const MilkRecordsTable = (data) => {

    return (
    <div className="wrapper ">
        <SideBar pageActive={"milkCollection"} />
        <div className="main-panel">
            <NavBar page={["Milk Collection", "Martin"]}/>
            <div className="content">
                <div className="container-fluid">
                    <MilkRecordTable />
                </div>
            </div>
        </div>
    </div>
    )
}

export default MilkRecordsTable