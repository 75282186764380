import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ dairyPoints }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const userFirstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const capitalizeWord = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }

    //set error messages
    const [errFirstname, setErrFirstNameMsg] = useState('');
    const [errOtherNames, setErrOtherNamesMsg] = useState('');
    const [errPhoneNumber, setErrPhoneNumberMsg] = useState('');
    const [errEmail, setErrEmailMsg] = useState('');
    const [errBranch, setErrBranchMsg] = useState('');

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [saccoBranchPoint, setPoint] = useState(getInitialStateBranch);

    const [firstName, setFirstName] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userEmail, setEmail] = useState('');

    const [portalAccess, setPortalAccess] = useState(false);
    const [portalViewRecords, setViewRecords] = useState(false);
    const [portalDownloadRecords, setDownloadRecords] = useState(false);
    const [portalManageDairySacco, setManageDairySacco] = useState(false);
    const [portalUpdateFarmerRecords, setUpdateFarmerRecords] = useState(false);
    const [appAccess, setAccessApp] = useState(false);
    const [issueCash, setIssueCash] = useState(false);
    const [emailReports, setEmailReports] = useState(false);

    const [spinner, setSpinier] = useState('');

    const onBranchChange = (e) => {
        setPoint(e.target.value);
    };

    const accessPortal = async (e) => {

        if (e.target.checked === true) {
            setPortalAccess(true);
        } else {
            setPortalAccess(false);
        }
    };

    const viewRecords = async (e) => {
        if (e.target.checked === true) {
            setViewRecords(true);
        } else {
            setViewRecords(false);
        }
    }

    const downloadRecords = async (e) => {
        if (e.target.checked === true) {
            setDownloadRecords(true);
        } else {
            setDownloadRecords(false);
        }
    }

    const manageDairySacco = async (e) => {
        if (e.target.checked === true) {
            setManageDairySacco(true);
        } else {
            setManageDairySacco(false);
        }
    }

    const updateFarmerRecords = async (e) => {
        if (e.target.checked === true) {
            setUpdateFarmerRecords(true);
        } else {
            setUpdateFarmerRecords(false);
        }
    }

    const accessApp = async (e) => {
        if (e.target.checked == true) {
            setAccessApp(true);
        } else {
            setAccessApp(false);
        }
    }

    const issueCashAdvance = async (e) => {
        if (e.target.checked == true) {
            setIssueCash(true);
        } else {
            setIssueCash(false);
        }
    }

    const receiveEmailReports = async (e) => {

        if (e.target.checked === true) {
            setEmailReports(true);
        } else {
            setEmailReports(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (firstName === null || firstName === '') {
            setErrFirstNameMsg('First name is required');
        } else {
            setErrFirstNameMsg('');
        }

        if (otherNames === null || otherNames === '') {
            setErrOtherNamesMsg('Other names is required');
        } else {
            setErrOtherNamesMsg('');
        }

        if (userEmail === null || userEmail === '') {
            setErrEmailMsg('Email is required');
        } else {
            setErrEmailMsg('');
        }

        if (phoneNumber === null || phoneNumber === '') {
            setErrPhoneNumberMsg('Phone number is required');
        } else {
            setErrPhoneNumberMsg('');
        }

        if (saccoBranchPoint === null || saccoBranchPoint === 'select') {
            setErrBranchMsg('select employee dairy point');
        } else {
            setErrBranchMsg('');
        }

        if (firstName !== '' && otherNames !== '' && userEmail !== '' && phoneNumber !== '' && saccoBranchPoint !== 'select') {




            if (portalViewRecords === true || portalDownloadRecords === true || portalManageDairySacco === true || portalUpdateFarmerRecords === true) {
                if (portalAccess === false) {
                    Swal.fire('Access portal is for the selected role.');
                } else {
                    addEmployeeDetails();
                }
            } else if (portalViewRecords === false && portalDownloadRecords === false && portalManageDairySacco === false && portalUpdateFarmerRecords === false && portalAccess === false && appAccess === false && issueCash === false && emailReports === false) {

                Swal.fire({
                    title: 'Are you sure?',
                    text: "No roles have been assigned do you wish to continue?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Continue'
                }).then((result) => {
                    if (result.isConfirmed) {
                        addEmployeeDetails()
                    }
                })
            }
            else {
                addEmployeeDetails();
            }
        }
    }

    const addEmployeeDetails = () => {

        Swal.fire({
            title: '<strong>Add Employee</strong>',
            icon: 'info',
            html:
                '<b>Do you wish to save the following employee details</b><br />, ' +
                'First Name ' + firstName +
                '<br />Other Name: ' + otherNames +
                '<br />Email: ' + userEmail +
                '<br />Phone Number: ' + phoneNumber +
                '<br />Other Name: ' + otherNames +
                '<br />Portal Access: ' + portalAccess +
                '<br />Portal View Records: ' + portalViewRecords +
                '<br />Portal Download Records: ' + portalDownloadRecords +
                '<br />Portal Manage Dairy Sacco: ' + portalManageDairySacco +
                '<br />Portal Update Farmer Records: ' + portalUpdateFarmerRecords +
                '<br />App Access: ' + appAccess +
                '<br />Receive Email Reports: ' + emailReports,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue'
        }).then((result) => {
            if (result.isConfirmed) {

                setSpinier('fa fa-spinner fa-spin');
                var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addEmployee";

                const headers = {
                    'Authorization': `Bearer ${sessionID}`,
                    "Content-Type": "application/json"
                };

                axios.post(filterURL, {
                    firstName: capitalizeWord(firstName),
                    otherNames: capitalizeWord(otherNames),
                    userEmail: userEmail,
                    phoneNumber: phoneNumber,
                    dairyPoint: saccoBranchPoint,
                    portalAccess: portalAccess,
                    portalViewRecords: portalViewRecords,
                    portalDownloadRecords: portalDownloadRecords,
                    portalManageDairySacco: portalManageDairySacco,
                    portalUpdateFarmerRecords: portalUpdateFarmerRecords,
                    appAccess: appAccess,
                    issueCashAdvance: issueCash,
                    emailReports: emailReports,
                    email: email,
                    token: token,
                    saccoBranchId: saccoBranchId
                }, { headers })
                    .then(function (response) {
                        setSpinier('');
                        setError('error')
                        setFirstName('');
                        setOtherNames('');
                        setPhoneNumber('');
                        setEmail('');
                        setPoint(getInitialStateBranch);
                        setPortalAccess(false);
                        setViewRecords(false);
                        setDownloadRecords(false);
                        setManageDairySacco(false);
                        setUpdateFarmerRecords(false);
                        setAccessApp(false);
                        Swal.fire(
                            response.data.status,
                            response.data.data[0].msg,
                            'success'
                        );
                    })
                    .catch(function (error) {
                        setSpinier('');
                        if (error.response.status === 401) {
                            navigate('/');
                        }
                        console.log("Catch : " + error);
                        console.log("Catch : " + error.response.status);
                        console.log(error.response.data);
                        setFormError(error.response.data.message)
                        setError('error-block')

                    });
            }
        });


    }

    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="addEmployee">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label id="firstNameError" className="bmd-label-floating">First Name</label>
                                        <input value={firstName} type="text" className="form-control" id="firstName" minlength="3" maxlength="20" onChange={(e) => setFirstName(e.target.value)} />
                                        <small className="my-error">{errFirstname}</small>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label id="secondNameError" className="bmd-label-floating">Other Name</label>
                                        <input value={otherNames} type="text" className="form-control" id="secondName" minlength="3" maxlength="20" onChange={(e) => setOtherNames(e.target.value)} />
                                        <small className="my-error">{errOtherNames}</small>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label id="PhoneNumberError" className="bmd-label-floating">PhoneNumber</label>
                                        <input value={phoneNumber} type="text" className="form-control" id="PhoneNumber" minlength="8" maxlength="15" onChange={(e) => setPhoneNumber(e.target.value)} />
                                        <small className="my-error">{errPhoneNumber}</small>
                                    </div>
                                    <div className="form-group">
                                        <label id="emailError" className="bmd-label-floating">Email</label>
                                        <input value={userEmail} type="email" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} />
                                        <small className="my-error">{errEmail}</small>
                                    </div>
                                    <div className="form-group">
                                        <p id="Dairy-PointError" className="bmd-label-floating">Location</p>
                                        <select id="Dairy-Point" className="form-control" value={saccoBranchPoint} onChange={onBranchChange}>
                                            <option value="select">Select Dairy Branch</option>
                                            {
                                                dairyPoints.map((points, i) => (
                                                    <option key={i} value={points.branchId}>{points.saccoBranch}</option>
                                                ))
                                            }
                                        </select>
                                        <small className="my-error">{errBranch}</small>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <p id="Dairy-PointError" className="bmd-label-floating">Roles Portal</p>
                                                <div title="User will be able to access the portal and view dairy statistics">
                                                    <input className="roles-input" type="checkbox" id="accessPortal" value="AccessPortal" onChange={accessPortal} />
                                                    <label htmlFor="accessPortal">Access Portal</label>
                                                </div>
                                                <div title="User will be able to view farmer records">
                                                    <input className="roles-input" type="checkbox" id="viewRecords" value="ViewRecords" onChange={viewRecords} />
                                                    <label htmlFor="viewRecords">View Records</label>
                                                </div>
                                                <div title="User can download sacco records">
                                                    <input className="roles-input" type="checkbox" id="downloadRecords" value="DownloadRecords" onChange={downloadRecords} />
                                                    <label htmlFor="downloadRecords">Download Records</label>
                                                </div>
                                                <div title="User will be able to manage dairy sacco activities">
                                                    <input className="roles-input" type="checkbox" id="manageDairySacco" value="ManageDairySacco" onChange={manageDairySacco} />
                                                    <label htmlFor="manageDairySacco">Manage Dairy Sacco</label>
                                                </div>
                                                <div title="user can update famer records">
                                                    <input className="roles-input" type="checkbox" id="updateFarmerRecords" value="UpdateFarmerRecords" onChange={updateFarmerRecords} title="user can update famer records" />
                                                    <label htmlFor="UpdateFarmerRecords">Update Farmer's Records</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <p id="Dairy-PointError" className="bmd-label-floating">Roles App</p>
                                                <div title="User will be able to access app">
                                                    <input className="roles-input" type="checkbox" id="accessApp" value="AccessApp" onChange={accessApp} />
                                                    <label htmlFor="accessApp">Access App</label>
                                                </div>
                                                <div title="user will be able to issue cash advance">
                                                    <input className="roles-input" type="checkbox" id="issueCashAdvance" value="issueCashAdvance" onChange={issueCashAdvance} title="user can update famer records" />
                                                    <label htmlFor="issueCashAdvance">Issue Cash Advance</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <p id="Dairy-PointError" className="bmd-label-floating">Email Reports</p>
                                                <div title="User will receive daily reports email" className="form-group">
                                                    <input className="roles-input" type="checkbox" id="accessApp" value="AccessApp" onChange={receiveEmailReports} />
                                                    <label htmlFor="accessApp">Receive email reports</label><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="AddEmployee">Add Employee&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}