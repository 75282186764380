import React, { useState, useEffect } from "react";
import LinkMilkCollectionMonthSearchTable from "../viewRecords/LinkMilkCollectionMonthSearchTable";
import LinkMilkCollectionSearchTable from "../viewRecords/LinkMilkCollectionSearchTable";
import LinkRejectedMilkMonthSearchTable from "../viewRecords/LinkRejectedMilkMonthSearchTable";
import LinkRejectedMilkSearchTable from "../viewRecords/LinkRejectedMilkSearchTable";

export default function ({search}) {

    const [componentsDay, setDayComponents] = useState();
    const [componentsMonth, setMonthComponents] = useState();

    useEffect(()=>{
        if(search[3] === "Collected"){
            setDayComponents(<LinkMilkCollectionSearchTable searchData={[search[1], search[2], search[3], search[4] ]} />);
            setMonthComponents(<LinkMilkCollectionMonthSearchTable searchData={[search[1], search[2], search[3], search[4] ]} />);
        } else if (search[3] === "Rejected"){
            setDayComponents(<LinkRejectedMilkSearchTable searchData={[search[1], search[2], search[3], search[4] ]} />);
            setMonthComponents(<LinkRejectedMilkMonthSearchTable searchData={[search[1], search[2], search[3], search[4] ]} />);
        }
    });

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-chart">
                            <div className="card-header card-header-warning">
                                <div className="ct-chart" id="completedTasksChart">
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h4 className="card-title">{search[0].data[0].titleDay}</h4>
                                        <p className="card-category">{search[0].data[0].category}</p>
                                        <h3 className="card-title">{search[0].data[0].dayRecord}</h3>
                                    </div>
                                    <div className="card-footer">
                                        <div className="stats">
                                            <form id="searchDayExcel">
                                                <input type="hidden" className="form-control" id="Sacco" value="'.$searchVall['Sacco'].'" />
                                                <input type="hidden" className="form-control" id="Record" value="'.$searchVall['Record'].'" />
                                                <input type="hidden" className="form-control" id="RecordState" value="'.$searchVall['RecordState'].'" />
                                                <input type="hidden" className="form-control" id="Date" value="'.$searchVall['Date'].'" />
                                                {componentsDay}
                                                {/* <button id="BtnDaySearchMilkExcel" type="submit" className="btn btn-sm btn-primary green"
                                                    name="Day-Milk-Collection-Excel"><i id="iSearchDay"></i> Download Excel file</button> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h4 className="card-title">{search[0].data[0].titleMonth}</h4>
                                        <p className="card-category">{search[0].data[0].category}</p>
                                        <h3 className="card-title">{search[0].data[0].monthRecord}</h3>
                                    </div>
                                    <div className="card-footer">
                                        <div className="stats">
                                            <form id="searchMonthExcel">
                                                <input type="hidden" className="form-control" id="Sacco" value="'.$searchVall['Sacco'].'" />
                                                <input type="hidden" className="form-control" id="Record" value="'.$searchVall['Record'].'" />
                                                <input type="hidden" className="form-control" id="RecordState" value="'.$searchVall['RecordState'].'" />
                                                <input type="hidden" className="form-control" id="Date" value="'.$searchVall['Date'].'" />
                                                {componentsMonth}
                                                {/* <button id="BtnMonthSearchMilkExcel" type="submit" className="btn btn-sm btn-primary green"
                                                    name="Month-Milk-Collection-Excel"><i id="iSearchMonth"></i> Download Excel file</button> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}