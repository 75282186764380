import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ animalBreeds }) {

  const navigate = useNavigate();

  const sessionID = localStorage.getItem("MilkCardSmsSessionId");
  const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
  const token = localStorage.getItem("MilkCardSmsTokenKey");
  const email = localStorage.getItem("MilkCardSmsEmail");
  const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

  const [spinner, setSpinier] = useState('');

  const [formError, setFormError] = useState('');
  const [error, setError] = useState('error');

  const getInitialStateBranch = () => {
    const value = "select";
    return value;
  };

  const [inseminatedBreed, setInseminatedBreed] = useState(getInitialStateBranch);
  const [breedState, setBreedState] = useState(getInitialStateBranch);

  const [errInseminatedBreed, setErrInseminatedBreedMsg] = useState('');
  const [errBreedState, setErrBreedStateMsg] = useState('');

  const onInseminatedBreedChange = (e) => {
    setInseminatedBreed(e.target.value);
  };

  const onBreedStateChange = (e) => {
    setBreedState(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inseminatedBreed === '' || inseminatedBreed === null || inseminatedBreed === 'select') {
      setErrInseminatedBreedMsg('Breed is required');
    } else {
      setErrInseminatedBreedMsg('');
    }

    if (breedState === '' || breedState === null || breedState === 'select') {
      setErrBreedStateMsg('Breed State is required');
    } else {
      setErrBreedStateMsg('');
    }

    if (inseminatedBreed !== 'select' && breedState !== 'select') {

      setSpinier('fa fa-spinner fa-spin');

      var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/deleteInseminationBreeds";

      const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
      };

      axios.post(filterURL, {
        sacco: sacco,
        breed: inseminatedBreed,
        recordState: breedState,
        email: email,
        token: token,
        saccoBranchId: saccoBranchId
      }, { headers })
        .then(function (response) {
          setSpinier('');
          reloadPage();
          setError('error');
          setFormError('');
          Swal.fire(
            response.data.status,
            response.data.data[0].msg,
            'success'
          );
        })
        .catch(function (error) {
          setSpinier('');
          if (error.response.status === 401) {
            navigate('/');
          }
          if (error.response.status == 422) {
            if (error.response.data.hasOwnProperty('breed')) {
              console.log(error.response.data.breed[0]);
              setFormError(error.response.data.breed[0])
            } else if (error.response.data.hasOwnProperty('sacco')) {
              console.log(error.response.data.sacco[0]);
              setFormError(error.response.data.sacco[0])
            }
          } else {
            setFormError(error.response.data.message)
          }
          setError('error-block')

        });

    }
  }

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  }


  return (
    <div className="card-body">
      <div className="tab-content">
        <div className="tab-pane active" id="messages">
          <div className="card-body">
            <p className={error}>{formError}</p>
            <form onSubmit={handleSubmit} id="deleteBreed">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Delbreed" id="DelbreedError" className="card-category">Select Breed</label>
                    <select id="Delbreed" className="form-control" value={inseminatedBreed} onChange={onInseminatedBreedChange}>
                      <option value="select">Select inseminated Breed</option>
                      {
                        animalBreeds.map((breed, i) => (
                          <option key={i} value={breed.breedId}>{breed.breedName}</option>
                        ))
                      }
                    </select>
                    <small className="my-error">{errInseminatedBreed}</small>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="breedState" className="card-category">Breed State</label>
                    <select id="breedState" className="form-control" value={breedState} onChange={onBreedStateChange}>
                      <option value="select">Select Breed State</option>
                      <option value="1">Active</option>
                      <option value="0">Disabled</option>
                    </select>
                    <small className="my-error">{errBreedState}</small>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary pull-right" id="deleteBreed">Update Breed&nbsp;<i className={spinner}></i></button>
              <div className="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}