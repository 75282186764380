import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import axios from "axios";
import Swal from 'sweetalert2';

export default function ({ tableData }) {

    const location = useLocation()

    console.log(location.state.page);

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };


    //   var milkRecordPage = "";

    //   if(location.state.page === "MonthMilkCollection"){
    //       milkRecordPage = "monthMilkCollection";
    //   } else if (location.state.page === "TodayMilkCollection"){
    //       milkRecordPage = "todayMilkCollection"
    //   }
    const [data, setData] = useState([]);

    useEffect(() => {

        if (location.state.page === "todayRejectedMilk" || location.state.page === "monthRejectedMilk") {

            var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/MilkCollection/milkCollectionData";

                axios.post(filterURL, {
                    sacco: sacco,
                    email: email,
                    token: tokenKey,
                    recordType: location.state.page,
                    saccoBranchId: saccoBranchId
                }, { headers })
                    .then(function (response) {
                        if (response.data.data.length < 1) {
                            Swal.fire('No records found!');
                        } else {
                            setData(response.data.data);
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            navigate('/');
                        }
                        console.log(error);
                    });
        } else if(location.state.page === "SearchDayRejectedMilk" || location.state.page === "SearchMonthRejectedMilk"){
            var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/MilkCollection/milkCollectionSearchData";
    
            
            axios.post(filterURL, {
                sacco: sacco,
                email: email,
                token: tokenKey,
                saccoBranch: location.state.search.searchData[0],
                searchDate: location.state.search.searchData[1],
                recordType: location.state.page,
                recordState: location.state.search.searchData[3],
                saccoBranchId: saccoBranchId
            }, { headers })
                .then(function (response) {
                    if (response.data.data.length < 1) {
                        Swal.fire('No records found!');
                    } else {
                        setData(response.data.data);
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        navigate('/');
                    }
                    console.log(error);
                })
                .then(() => {});
            
        }

    }, []);

    const column = [
        { title: "Farmer Number", field: "farmersNumber" },
        { title: "Milk Liters", field: "milkLiters" },
        { title: "Date and Time", field: "recordDateTime" },
        { title: "Milk Test", field: "milkTest" },
        { title: "Dairy Point", field: "saccoBranch" }
    ];

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-chart">
                            <div className="card-header card-header-warning">
                                <div className="ct-chart" id="completedTasksChart">
                                    <h3 className="card-title">Test Dairy Sacco</h3>
                                    <p className="card-category">Record Type: Collected milk<br />Record State: Active records <br /> Search Date: 2022-07-10</p>
                                </div>
                            </div>
                            <MaterialTable
                                title=''
                                data={data}
                                columns={column}
                                options={{
                                    exportMenu: [
                                        {
                                            label: "Export PDF",
                                            exportFunc: (cols, datas) =>
                                                ExportPdf(cols, data, "myPdfFileName"),
                                        },
                                        {
                                            label: "Export CSV",
                                            exportFunc: (cols, datas) =>
                                                ExportCsv(cols, data, "myCsvFileName"),
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}