import React, { useState, useEffect } from "react";
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.jpg";
import axios from "axios";

export default function (props) {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [spinner, setSpinier] = useState('');

    //set error messages
    const [errEmail, setErrEmailMsg] = useState('');
    const [formError, setFormError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinier('fa fa-spinner fa-spin');

        if (email === '') {
            setErrEmailMsg('Email address is required');
        } else {
            setErrEmailMsg('')
        }

        if (email !== '') {
            var loginUrl = "https://debug.martinnganganjoroge.com/gateway/admin/Request/admin/forgotPassword";

            const headers = {};

            axios.post(loginUrl, {
                email: email
            }, { headers })
                .then(function (response) {
                    console.log(response.data.data);
                    setSpinier('');
                    navigate('/forgotPasswordCode?Email='+response.data.data);
                })
                .catch(function (error) {
                    // console.log("Catch : " + error);
                    //     console.log("Catch : " + error.response.status);
                    //     console.log(error.response.data);
                    setSpinier('');
                    setFormError(error.response.data.message)
                });

        } else { setSpinier(''); }
    }

    return (
        <section className="container-fluid min-vh-100">
            <div className="d-sm-flex justify-content-center">
                <div className="col-sm-8 col-md-6 col-lg-3 p-5 bg-light mt-5 box-shadow rounded">
                    <form onSubmit={handleSubmit} id="send-message" className="form-padd">
                        <div className="text-center mb-4">
                            <img className="mb-1" src={logo} alt="" width="150" height="121" />
                            <h1 className="century-600">MilkCardSms</h1>
                            <p className="h3 lato-600">Forgot Password</p>
                            <small className="my-error">{formError}</small>
                        </div>
                        <div className="mb-3">
                            <label className="aileron" htmlFor="full-name">Email</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control form-control-lg border border-dark lato input-p" id="full-name" placeholder="Enter your email adress" />
                            <small className="my-error">{errEmail}</small>
                        </div>
                        <div className="d-flex mt-3">
                            <button type="submit" className="btn btn-primary w-50 btn-submit border-0 py-3 fw-bolder mx-auto brown text-white aileron">
                                Send Code&nbsp;<i className={spinner}></i>
                            </button>
                        </div>
                        <Link className="nav-link" to="/">
                        <p className="text-center mt-3 blue">Login</p>
                        </Link>
                    </form>
                </div>
            </div>

        </section>
    )
}