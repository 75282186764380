import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import CardBrown from "../components/card/CardBrown";
import CardYellow from "../components/card/CardYellow";
import CardSearchAiRecord from "../components/card/CardSearchAiRecord";
import Footer from "../components/footer/Footer";
import SpinnerLoader from "../components/loader/SpinnerLoader";

import axios from "axios";


const ArtificialInsemination = () => {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [artificialInseminationStats, setArtificialInsemination] = useState([]);

    var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ArtificialInsemination/ArtificialInseminationStats";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: tokenKey,
            saccoBranchId: saccoBranchId
          }, { headers })
          .then(function (response) {
            setArtificialInsemination(response.data);
            console.log(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
                navigate('/');
            }
          });
        
    }, []);

    if (artificialInseminationStats.length < 1) {
        return (
            <SpinnerLoader page="saccoAi" />
        )
    }

    return (

        <div className="wrapper ">
            <SideBar pageActive={"saccoAi"}/>
            <div className="main-panel">
                <NavBar page={["Artificial Insemination", firstName]}/>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <CardBrown brownData={["Today's Artificial Insemination", "Number of breeds", artificialInseminationStats.data[0].todayAi]} />
                            <CardYellow yellowData={["Months's Artificial Insemination", "Number of breeds", artificialInseminationStats.data[0].monthAi]}/>
                        </div>
                        <CardSearchAiRecord />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default ArtificialInsemination