import React from "react";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import ReportFull from "../components/tables/ReportTableFullFarmerRecords";

const FullSaccoReport = () => {

    return (
    <div className="wrapper ">
        <SideBar pageActive={"fullSaccoReport"} />
        <div className="main-panel">
            <NavBar page={["Farmers Full Report", "Martin"]}/>
            <div className="content">
                <div className="container-fluid">
                    <ReportFull />
                </div>
            </div>
        </div>
    </div>
    )
}

export default FullSaccoReport