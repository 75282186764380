import React from "react";
import ForgotPasswordForm from "../components/authentication/ForgotPasswordForm";

const ForgotPassword = () => {

    return (
    <div className="wrapper ">
        <ForgotPasswordForm />
    </div>
    )
}

export default ForgotPassword