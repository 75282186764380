import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import axios from "axios";
import Swal from 'sweetalert2';

export default function ({ search }) {

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const navigate = useNavigate();

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const [style, setStyle] = useState('modal');

    const [farmer, setFarmer] = useState('');
    const [item, setItem] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [date, setDateTime] = useState('');
    const [branch, setBranch] = useState('');
    const [branchId, setBranchId] = useState('');


    const editMilkRecord = (rowData) => {
        console.log(rowData);

        setFarmer(rowData.farmersNumber);
        setItem(rowData.item);
        setQuantity(rowData.quantity);
        setPrice(rowData.price);
        setDateTime(rowData.recordDateTime);
        setBranchId(rowData.branchId);

        setStyle('modal-open');
    };

    const disableMilkRecord = (rowData) => {
        console.log(rowData);
    
        Swal.fire({
          title: '<strong>Are you sure?</strong>',
          html:
            'You want to disable the following record, <br />' +
            '<br />Farmer Number : ' + rowData.farmersNumber +
            '<br />Item : ' + rowData.item +
            '<br />Quantity : ' + rowData.quantity +
            '<br />Price : ' + rowData.price +
            '<br />Date : ' + rowData.recordDateTime +
            '<br />Sacco Branch : ' + rowData.saccoBranch +
            '<br /><br />',
          icon: 'warning',
          input: 'text',
          inputAttributes: {
            placeholder: 'Disable reason'
          },
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Disable',
          preConfirm: (reason) => {
            if (reason == "" || reason == null || reason.length < 15) {
              Swal.showValidationMessage("Disable reason should be more than 15 characters");
            } else {
                farmerParticularRecordAction("Disable", reason, rowData.farmersNumber, rowData.milkLiters, rowData.recordDateTime, rowData.branchId);
            }
          }
        }).then((result) => {
          // if (result.isConfirmed) {
          //   farmerParticularRecordAction("Disable", "reason", rowData.farmersNumber, rowData.milkLiters, rowData.recordDateTime, rowData.branchId);
          // }
        })
    
    
      };
    
      const enableMilkRecord = (rowData) => {
        console.log(rowData);
    
        Swal.fire({
          title: '<strong>Are you sure?</strong>',
          html:
            'You want to enable the following record, <br />' +
            '<br />Farmer Number : ' + rowData.farmersNumber +
            '<br />Item : ' + rowData.item +
            '<br />Quantity : ' + rowData.quantity +
            '<br />Price : ' + rowData.price +
            '<br />Date : ' + rowData.recordDateTime +
            '<br />Sacco Branch : ' + rowData.saccoBranch +
            '<br /><br />',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Enable'
        }).then((result) => {
          if (result.isConfirmed) {
            farmerParticularRecordAction("Enable", "reason", rowData.farmersNumber, rowData.milkLiters, rowData.recordDateTime, rowData.branchId);
          }
        })
    
      };

    const closeModal = () => {
        setFarmer('');
        setItem('');
        setDateTime('');
        setBranch('');
        setStyle('modal')
    }

    const column = [
        { title: "Farmer Number", field: "farmersNumber" },
        { title: "Item", field: "item" },
        { title: "Quantity", field: "quantity" },
        { title: "Particular Price", field: "partPrice" },
        { title: "Date & Time", field: "recordDateTime" },
        { title: "Sacco Branch/Point", field: "saccoBranch" },
        {
            field: "url",
            title: "Action",
            render: (rowData) => {
                return (
                    <div>
                        <button title="Edit Record" onClick={() => editMilkRecord(rowData)} className='btn btn-warning'><i className="bi bi-pencil-square"></i></button>
                        <button title="Enable Record" onClick={() => enableMilkRecord(rowData)} className='btn btn-success' href={rowData.farmersNumber}><i className="bi bi-check"></i></button>
            <button title="Delete Record" onClick={() => disableMilkRecord(rowData)} className='btn btn-danger' href={rowData.farmersNumber}><i className="bi bi-trash-fill"></i></button>
                    </div>
                );
            },
        },
    ];

    const handleSubmit = async (e) => {
        //console.log(farmer, milkLiters, date, branch)

        farmerParticularRecordAction("Update", "reason", farmer, item, quantity, price, date, branchId);
    }

    const farmerParticularRecordAction = (action, reason, far, ite, qua, pri, dattim, braId) => {

        const headers = {
          'Authorization': `Bearer ${sessionID}`,
          "Content-Type": "application/json"
        };
    
        var farmerRecordSearchURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/FarmersRecords/farmerUpdateParticularRecords";
    
                axios.post(farmerRecordSearchURL, {
                    farmerNumber: far,
                    item: ite,
                    price: pri,
                    quantity: qua,
                    recordAction: action,
                    date: dattim,
                    email: email,
                    token: tokenKey,
                    saccoBranchId: saccoBranchId,
                    milkBranchId: braId,
                    reason:reason
                }, { headers })
                    .then(function (response) {
                        console.log(response.data);
                        this.closeModal();
                        Swal.fire('Record updated successfully');
                    })
                    .catch(function (error) {
                      
                        if (401 === error.response.status) {
                            navigate('/');
                        } else {
                            setFormError(error.response.data.message)
                            setError('error-block');
                        }
    
                        return Promise.reject(error);
                            
                    })
                    .then(() => { });
    
    
      }


    return (
        <div className="content">
            <div className="container-fluid">


                <div id="myModal" className={style}>
                    <div className="modal-content">
                        <span className="close" onClick={() => closeModal()}>&times;</span>
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Update Farmer Milk Collection Records</h4>
                                <p className="card-category">You can update farmer collected milk records from this window</p>
                            </div>
                            <div className="card-body">
                            <p for="ToolPick" className={error}>{formError}</p>
                                <form onSubmit={handleSubmit} id="FarmerParticularUpdate">
                                    <p className="card-category">Farmers Number</p>
                                    <input value={farmer} type="text" className="form-control" id="PartFarmersNumb" readOnly />
                                    <p id="PartFarmersItemError" className="card-category">Particular Item</p>
                                    <select id="PartFarmersItem" className="form-control">
                                    </select>
                                    <p id="PartQuantityError" className="card-category">Particular Quantity</p>
                                    <input type="text" className="form-control" id="PartQuantity" />
                                    <p id="PartPriceError" className="card-category">Particular Price</p>
                                    <input type="text" className="form-control" id="PartPrice" />
                                    <p className="card-category">Particular Sale Date Time</p>
                                    <input value={branch} type="text" className="form-control" id="PartFarmersDate" readOnly />
                                    <p className="card-category">Sacco Branch</p>
                                    <input type="text" className="form-control" id="PartsaccoBranch" readOnly />
                                    <button type="submit" id="RecordUpdate" className="btn btn-sm btn-primary"><i id="iRecordUpdate"></i> Update Record</button>
                                    <button type="submit" id="RecordUpdateCancle" className="btn btn-sm btn-primary dark-sky">Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >


                <div className="row">
                    <div className="col-md-12">
                        <MaterialTable
                            title=''
                            data={search[0]}
                            columns={column}
                            options={{
                                exportMenu: [
                                    {
                                        label: "Export PDF",
                                        exportFunc: (cols, datas) =>
                                            ExportPdf(cols, datas, search[2]),
                                    },
                                    {
                                        label: "Export CSV",
                                        exportFunc: (cols, datas) =>
                                            ExportCsv(cols, datas, search[2]),
                                    },
                                ],
                            }}
                            renderSummaryRow={({ column, data }) =>
                                column.field === "partPrice"
                                    ? {
                                        value: "Total : " + search[1][0].recordSum,
                                        style: { background: "#238756d1", color: "#ffffff" },
                                    }
                                    : undefined
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}