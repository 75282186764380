import React, { useState } from "react";
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';

export default function ({ pageActive }) {

    const sacco = localStorage.getItem("MilkCardSmsSacco");

    var navMilkCollection = [];
    var navFarmer = [];
    var navSales = [];
    var navAi = [];
    var navManage = [];
    var navReports = [];
    var navLogout = [];

    var milkReport = [];
    var particularReport = [];
    var fullReport = [];
    var cashReport = [];
    var farmersReport = [];

    if (pageActive === "milkCollection") {
        navMilkCollection.push("nav-item active");
    } else {
        navMilkCollection.push("nav-item")
    }

    if (pageActive === "saccoFarmers") {
        navFarmer.push("nav-item active");
    } else {
        navFarmer.push("nav-item")
    }

    if (pageActive === "saccoSale") {
        navSales.push("nav-item active");
    } else {
        navSales.push("nav-item")
    }

    if (pageActive === "saccoAi") {
        navAi.push("nav-item active");
    } else {
        navAi.push("nav-item")
    }

    if (pageActive === "manageSacco") {
        navManage.push("nav-item active");
    } else {
        navManage.push("nav-item")
    }

    if (pageActive === "saccoReport") {
        navReports.push("nav-item active");
    } else {
        navReports.push("nav-item")
    }

    if (pageActive === "logout") {
        navLogout.push("nav-item active");
    } else {
        navLogout.push("nav-item")
    }


    //
    if (pageActive === "farmersMilkReport") {
        milkReport.push("nav-item active");
    } else {
        milkReport.push("nav-item")
    }

    if (pageActive === "farmersParticularReport") {
        particularReport.push("nav-item active");
    } else {
        particularReport.push("nav-item")
    }


    if (pageActive === "farmersFullReport") {
        farmersReport.push("nav-item active");
    } else {
        farmersReport.push("nav-item")
    }

    if (pageActive === "cashAdvanceReport") {
        cashReport.push("nav-item active");
    } else {
        cashReport.push("nav-item")
    }

    if (pageActive === "fullSaccoReport") {
        fullReport.push("nav-item active");
    } else {
        fullReport.push("nav-item");
    }

    const logOut = async (e) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to log off",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Log Off'
        }).then((result) => {
            if (result.isConfirmed) {
                userLogOff();
            }
        })
    }

    const userLogOff = async (e) => {
        //log Off
    }

    const [navStyle, setNavStyle] = useState('collapse');

    const navDropDown = async (e) => {

        if (navStyle === 'collapse') {
            setNavStyle('');
            setNavStyle("collapse show");
        } else {
            setNavStyle('');
            setNavStyle("collapse");
        }
    }

    return (
        <div id="side_nav" className="sidebar" data-color="purple" data-background-color="white" data-image="">
            <div className="logo">
                <Link className="simple-text logo-normal text-center" to="/dashboard/milkCollection">
                    MilkCardSms
                </Link>
                <h6 className="text-black text-center px-2">{sacco}</h6>
            </div>
            <div className="sidebar-wrapper">
                <ul className="nav">
                    <li className={navMilkCollection[0]}>
                        <Link className="nav-link" to="/dashboard/milkCollection">
                            <p>Milk Collection</p>
                        </Link>
                    </li>
                    <li className={navFarmer[0]}>
                        <Link className="nav-link" to="/dashboard/farmerRecords">
                            <p>Farmers Records</p>
                        </Link>
                    </li>
                    <li className={navSales[0]}>
                        <Link className="nav-link" to="/dashboard/DairySales">
                            <p>Sales</p>
                        </Link>
                    </li>
                    <li className={navAi[0]}>
                        <Link className="nav-link" to="/dashboard/ArtificialInsemination">
                            <p>Artificial Insemination</p>
                        </Link>
                    </li>
                    <li className={navManage[0]}>
                        <Link className="nav-link" to="/dashboard/ManageDairySacco">
                            <p>Manage Dairy Sacco</p>
                        </Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link " data-toggle="collapse" aria-expanded="true" onClick={() => navDropDown()}>

                            <p>Reports
                                <b className="caret"></b>
                            </p>
                        </a>
                        <div className={navStyle}>
                            <ul className="nav">
                                <li className={milkReport[0]}>
                                    <Link className="nav-link" to="/dashboard/Reports/milkCollectionReport">
                                        <p className="p-12 pl-3">Farmer's Milk Report</p>
                                    </Link>
                                </li>
                                <li className={particularReport[0]}>
                                    <Link className="nav-link" to="/dashboard/Reports/particularReport">
                                        <p className="p-12 pl-3">Farmer's Particular Report</p>
                                    </Link>
                                </li>
                                <li className={cashReport[0]}>
                                    <Link className="nav-link" to="/dashboard/Reports/cashReport">
                                        <p className="p-12 pl-3">Cash Advance Report</p>
                                    </Link>
                                </li>
                                <li className={farmersReport[0]}>
                                    <Link className="nav-link" to="/dashboard/Reports/farmersReport">
                                        <p className="p-12 pl-3">Full Farmer's Report</p>
                                    </Link>
                                </li>
                                <li className={fullReport[0]}>
                                    <Link className="nav-link" to="/dashboard/Reports/fullReport">
                                        <p className="p-12 pl-3">Full Sacco Report</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className={navLogout[0]}>
                        <Link className="nav-link" to="" onClick={logOut}>
                            <p>Logout</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>

    )
}