import React, { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {

    const [auth, setAuth] = useState({});

    localStorage.setItem('MilkCardSmsEmail', auth.userEmail);
    localStorage.setItem('MilkCardSmsFirstName', auth.firstName);
    localStorage.setItem('MilkCardSmsSacco', auth.dairySacco);
    localStorage.setItem('MilkCardSmsSaccoBranch', auth.saccoBranch);
    localStorage.setItem('MilkCardSmsBranchId', auth.branchId);
    localStorage.setItem('MilkCardSmsDairySaccoCode', auth.dairySaccoCode);
    localStorage.setItem('MilkCardSmsTokenKey', auth.tokenKey);
    localStorage.setItem('MilkCardSmsSessionId', auth.sessionID);

    return (
        <AuthContext.Provider value={{auth, setAuth}}>

            {children}

        </AuthContext.Provider>
    )
}

export default AuthContext;