import React, { useState, useEffect } from "react";
import LinkMilkCollectionTable from "../viewRecords/LinkMilkCollectionTable";
import LinkDairySalesTable from "../viewRecords/LinkDairySalesTable";

export default function ({brownData}) {

    const [components, setComponents] = useState();

    useEffect(()=>{
        if(brownData[3] === "MilkCollection"){
                setComponents(<LinkMilkCollectionTable />)
        } else if (brownData[3] === "DairySales"){
            setComponents(<LinkDairySalesTable />);
        }
    });

    return (

        <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                    <div className="card-icon">
                    </div>
                    <h4 className="card-title">{brownData[0]}</h4>
                    <p className="card-category">{brownData[1]}</p>
                    <h3 className="card-title">{brownData[2]}</h3>
                </div>
                <div className="card-footer">
                    <div className="stats">
                    {components}
                        {/* <button id="downloadRecordBrown" type="submit" className="btn btn-sm btn-primary green"
                            name="Today-Milk-Collection-Excel"><i id="todayMilk"></i> Download Excel file</button> */}
                    </div>
                </div>
            </div>
        </div>

    )
}