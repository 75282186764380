import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'

import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import axios from "axios";
import Swal from 'sweetalert2';

export default function ({ tableData }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");


    const [data, setData] = useState([]);

    var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/Reports/farmersReports";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: token,
            reportType: "FullFarmerReport"
        }, { headers })
            .then(function (response) {
                setData(response.data.data);
                console.log(response.data);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
            });

    }, []);


    if (!data) return null;

    if (data.length < 1) {
        Swal.fire('No records found!');
    }

    const column= [
        {title: "Farmer Number", field:"farmersNumber"},
        {title: "Milk Liters", field:"totalFarmerLiters"},
        {title: "Total Earnings", field:"totalFarmerearnings"},
        {title: "Total Particulars", field:"totalFarmerParticular"},
        {title: "Year $ Month", field:"yearDate"},
        {title: "Total Payout", field:"totalPayout"}
    ];

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-chart">
                            <div className="card-header card-header-warning">
                                <div className="ct-chart" id="completedTasksChart">
                                    <h3 className="card-title">Test Dairy Sacco</h3>
                                    <p className="card-category">Record Type: Collected milk<br />Record State: Active records <br /> Search Date: 2022-07-10</p>
                                </div>
                            </div>
                            <MaterialTable
                            title = ''
                            data = {data}
                            columns = {column}
                            options={{
                                exportMenu: [
                                  {
                                    label: "Export PDF",
                                    exportFunc: (cols, datas) =>
                                      ExportPdf(cols, data, "myPdfFileName"),
                                  },
                                  {
                                    label: "Export CSV",
                                    exportFunc: (cols, datas) =>
                                      ExportCsv(cols, data, "myCsvFileName"),
                                  },
                                ],
                              }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}