import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");


    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [customerName, setCustomerName] = useState('');
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
    const [customerType, setCustomerType] = useState(getInitialStateBranch);

    //set error messages
    const [errCustomerName, setErrCustomerName] = useState('');
    const [errCustomerType, setErrCustomerType] = useState('');

    const onCustomerTypeChange = (e) => {
        setCustomerType(e.target.value);
    };

    const [spinner, setSpinier] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (customerName === null || customerName === '') {
            setErrCustomerName('Customer name is required');
        } else {
            setErrCustomerName('');
        }

        if (customerType === 'select') {
            setErrCustomerType('Customer type is required');
        } else {
            setErrCustomerType('');
        }

        if (customerName !== '' && customerType !== 'select') {

            setSpinier('fa fa-spinner fa-spin');

            var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addCustomerDetails";

            const headers = {
                'Authorization': `Bearer ${sessionID}`,
                "Content-Type": "application/json"
            };

            axios.post(filterURL, {
                sacco: sacco,
                customerName: customerName,
                customerPhone: customerPhoneNumber,
                customerType: customerType,
                email: email,
                token: token,
                saccoBranchId: saccoBranchId
            }, { headers })
                .then(function (response) {
                    setError('error');
                    setSpinier('');
                    setFormError('');
                    setCustomerName('');
                    setCustomerPhoneNumber('');
                    setCustomerType(getInitialStateBranch);
                    Swal.fire(
                        response.data.status,
                        response.data.data[0].msg,
                        'success'
                    );
                })
                .catch(function (error) {
                    setSpinier('');
                    if (error.response.status === 401) {
                        navigate('/');
                    }
                    console.log("Catch : " + error);
                    console.log("Catch : " + error.response.status);
                    console.log(error.response.data);
                    setFormError(error.response.data.message)
                    setError('error-block')

                });
        }
    }
    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="saccoBreeds">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="customerName" className="bmd-label-floating">Customer Name</label>
                                        <input id="customerName" type="text" className="form-control" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                                        <small className="my-error">{errCustomerName}</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="customerNumber" className="bmd-label-floating">Customer PhoneNumber</label>
                                        <input id="customerNumber" type="text" className="form-control" value={customerPhoneNumber} onChange={(e) => setCustomerPhoneNumber(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="customerType" className="bmd-label-floating">Customer type</label>
                                        <select id="customerType" className="form-control" value={customerType} onChange={onCustomerTypeChange}>
                                            <option value="select">Select Customer Type</option>
                                            <option value="Individual">Individual</option>
                                            <option value="Institution">Institution</option>
                                        </select>
                                        <small className="my-error">{errCustomerType}</small>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="btnAddCustomer">Add Customer&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}