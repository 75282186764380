import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import SpinnerLoader from "../components/loader/SpinnerLoader";
import CardBrown from "../components/card/CardBrown";
import CardYellow from "../components/card/CardYellow";
import CardBrownLong from "../components/card/CardBrownLong";
import CardYellowLong from "../components/card/CardYellowLong";
import CardSearchMilkRecord from "../components/card/CardSearchMilkRecord";
import Footer from "../components/footer/Footer";

import axios from "axios";


const MilkCollection = () => {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [milkStats, setMilkStats] = React.useState([]);

    var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/MilkCollection/MilkStats";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: tokenKey,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setMilkStats(response.data);
                console.log(response.data);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                // console.log("Catch : " + error);
                // console.log("Catch : " + error.response.status);
                // console.log(error.response.data);
            });

    }, []);

    if (milkStats.length < 1) {
        return (
            <SpinnerLoader page="milkCollection" />
        )
    }

    return (
        <div className="wrapper ">
            <SideBar pageActive={"milkCollection"} />
            <div className="main-panel">
                <NavBar page={["Milk Collection", firstName]} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <CardBrown brownData={["Today's Milk Collection", "Milk in Liters", milkStats.data[0].todayCollection, "MilkCollection"]} />
                            <CardYellow yellowData={["Months's Milk Collection", "Milk in Liters", milkStats.data[0].monthCollection, "MilkCollection"]} />
                        </div>
                        <div className="row">
                            <CardBrownLong brownLongData={["Todays Rejected Milk", "Milk in Liters", milkStats.data[0].todayRejected, "RejectedMilk"]} />

                            <CardYellowLong yellowLongData={["Month's Rejected Milk", "Milk in Liters", milkStats.data[0].monthsRejected, "RejectedMilk"]} />
                        </div>
                        <CardSearchMilkRecord dairyBranches={milkStats.saccoBranches} />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default MilkCollection