import React, { useState, useEffect } from "react";
import LinkDairySalesTable from "../viewRecords/LinkDairySalesTable";
import LinkRe from "../viewRecords/LinkMilkCollectionMonthTable";
import LinkRejectedMilkTable from "../viewRecords/LinkRejectedMilkTable";

export default function ({brownLongData}) {

    const [components, setComponents] = useState();

    useEffect(()=>{
        if(brownLongData[3] === "RejectedMilk"){
                setComponents(<LinkRejectedMilkTable />)
        } else if (brownLongData[3] === "Particulars"){
            setComponents(<LinkDairySalesTable />);
        }
    });

    return (
        <div className="col-md-6">
            <div className="card card-chart">
                <div className="card-header card-header-success">
                    <div className="ct-chart" id="dailySalesChart"></div>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{brownLongData[0]}</h4>
                    <p className="card-category">{brownLongData[1]}</p>
                    <h3 className="card-title">{brownLongData[2]}</h3>
                </div>
                <div className="card-footer">
                    <div className="stats">
                    {components}
                        {/* <button id="btnTodayRejectedMilkExcel" type="submit" className="btn btn-sm btn-primary green"
                            name="Today-Rejected-Milk-Excel"><i id="todayRejected"></i> Download Excel file</button> */}
                    </div>
                </div>
            </div>
        </div>

    )
}