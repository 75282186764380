import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordCode from "./pages/ForgotPasswordCode";
import MilkCollection from "./pages/MilkCollection"
import MilkRecordsTable from "./pages/MilkRecordsTable";
import FarmersRecords from "./pages/FarmersRecords"
import DairySales from "./pages/DairySales"
import SaleRecordTable from "./pages/SaleRecordTable";
import ArtificialInsemination from "./pages/ArtificialInsemination";
import ManageDairySacco from "./pages/ManageDairySacco";
import ReportMilkCollection from "./pages/ReportMilkCollection";
import ReportParticulars from "./pages/ReportParticulars";
import ReportFullFarmers from "./pages/ReportFullFarmers";
import CashAdvanceReport from "./pages/CashAdvanceReport";
import FullSaccoReport from "./pages/FullSaccoReport";
import RejectedMilkTable from "./pages/RejectedMilkTable";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/forgotPasswordCode" element={<ForgotPasswordCode />} />
      <Route path="/dashboard/milkCollection" element={<MilkCollection />} />
      <Route path="/dashboard/milkCollection/milkCollectionTable" element={<MilkRecordsTable />} />
      <Route path="/dashboard/milkCollection/rejectedMilkTable" element={<RejectedMilkTable />} />
      <Route path="/dashboard/farmerRecords" element={<FarmersRecords />} />
      <Route path="/dashboard/DairySales" element={<DairySales />} />
      <Route path="/dashboard/DairySales/SalesTable" element={<SaleRecordTable />} />
      <Route path="/dashboard/ArtificialInsemination" element={<ArtificialInsemination />} />
      <Route path="/dashboard/ManageDairySacco" element={<ManageDairySacco />} />
      <Route path="/dashboard/Reports/milkCollectionReport" element={<ReportMilkCollection />} />
      <Route path="/dashboard/Reports/particularReport" element={<ReportParticulars />} />
      <Route path="/dashboard/Reports/farmersReport" element={<ReportFullFarmers />} />
      <Route path="/dashboard/Reports/cashReport" element={<CashAdvanceReport />} />
      <Route path="/dashboard/Reports/fullReport" element={<FullSaccoReport />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
