import React, { useState, useEffect } from "react";

import CardSearchResult from './CardSearchResult'
import axios from "axios";



const CardSearchAiRecord = () => {

    const [components, setComponents] = useState([]); 

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [date, setDate] = useState('');
    const [recordState, setRecordState] = useState(getInitialStateBranch);

    //set error messages
    const [errRecordState, setErrRecordStateMsg] = useState('');
    const [errDate, setErrDate] = useState('');

    useEffect(() => {
        setErrRecordStateMsg('');
    }, [recordState]);
    useEffect(() => {
        setErrDate('');
    }, [date]);

    const onRecordState = (e) => {
        setRecordState(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (recordState === "select") {
            setErrRecordStateMsg("Select a record state")
        } else {
            setErrRecordStateMsg("");
        }
        if(date === ""){
            setErrDate("Record date is required");
        } else {
            setErrDate("");
        }
        console.log(date, recordState);


        //milk record search

        if(date !== "" && recordState !== "select"){

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/MilkCollection/searchMilkRecord";

        const headers = {};

        axios.post(filterURL, {
            searchDate: date,
            recordState: recordState
        }, { headers })
            .then(function (response) {
                console.log(response.data);
                setComponents(<CardSearchResult search={response.data}/>)

                setRecordState(getInitialStateBranch);
                setDate('');
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(() => {});
    }

    }
    return (
        <div>
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-header card-header-tabs card-header-primary">
                            <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">
                                    <span className="nav-tabs-title">Search Artificial Insemination</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="tab-pane active" id="profile">
                                    <form onSubmit={handleSubmit} id="Search-form">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="date" className="card-category">Search Date</label>
                                                    <input id="date" type="date" name="Search-Milk-Collection-Date" className="form-control" onChange={(e) => setDate(e.target.value)}/>
                                                    <small className="my-error">{errDate}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="state" className="card-category">Record State</label>
                                                    <select value={recordState} id="state" name="Record-State" className="form-control" onChange={onRecordState}>
                                                        <option value="select">Select Record State</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Disabled">Disabled</option>
                                                    </select>
                                                    <small className="my-error">{errRecordState}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <button id="ButtonSearchMilkCollection" type="submit" className="btn btn-sm btn-primary"
                                            name="Button-Search-Milk-Collection"><i id="searchCollection"></i> Search Milk collection</button>
                                        <div className="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="search">
            {components}
            </div>
        </div>

    )
}

export default CardSearchAiRecord