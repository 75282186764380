import React from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");

    const [show, setShow] = React.useState('dropdown-menu dropdown-menu-right');

    const dropDownMenu = async (e) => {
        if (show === "dropdown-menu dropdown-menu-right") {
            setShow("dropdown-menu dropdown-menu-right show");
        } else {
            setShow("dropdown-menu dropdown-menu-right");
        }
    }

    const logOut = async (e) => {

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/admin/logOut";

        const headers = {
            'Authorization': `Bearer ${sessionID}`
        };

        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: tokenKey
        }, { headers })
            .then(function (response) {
                localStorage.removeItem("MilkCardSmsEmail");
                localStorage.removeItem("MilkCardSmsFirstName");
                localStorage.removeItem("MilkCardSmsSacco");
                localStorage.removeItem("MilkCardSmsSaccoBranch");
                localStorage.removeItem("MilkCardSmsDairySaccoCode");
                localStorage.removeItem("MilkCardSmsTokenKey");
                localStorage.removeItem("MilkCardSmsSessionId");
                navigate('/');
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
            })
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
            <div className="container-fluid">
                <div className="navbar-wrapper">
                    <Link className="navbar-brand" to="#">{page[0]}</Link>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown" onClick={() => dropDownMenu()}>
                            <i className="material-icons h3 px-3">Hello {page[1]}</i>
                            <div className={show} aria-labelledby="navbarDropdownProfile px-2" onClick={() => dropDownMenu()}>
                                <button id="changePassword" className="dropdown-item w-100" to="#">Change Password</button>
                                <div className="dropdown-divider"></div>
                                <button className="dropdown-item w-100" onClick={() => logOut()}>Log out</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}