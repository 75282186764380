import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const [animalBreed, setItem] = useState('');
    //set error messages
    const [errAnimalBreed, setErrCowBreedMsg] = useState('');

    const [spinner, setSpinier] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (animalBreed === '' || animalBreed === null) {
            setErrCowBreedMsg('Animal breed is required');
        } else {
            setErrCowBreedMsg('');
        }

        if (animalBreed !== '') {

            setSpinier('fa fa-spinner fa-spin');

            var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addInseminationBreeds";

            const headers = {
                'Authorization': `Bearer ${sessionID}`,
                "Content-Type": "application/json"
            };

            axios.post(filterURL, {
                sacco: sacco,
                email: email,
                token: token,
                breed: animalBreed,
                saccoBranchId: saccoBranchId
            }, { headers })
                .then(function (response) {
                    setError('error')
                    setFormError('')
                    setItem('');
                    setSpinier('');
                    Swal.fire(
                        response.data.status,
                        response.data.data[0].msg,
                        'success'
                    );
                    
                })
                .catch(function (error) {
                    setSpinier('');
                    if (error.response.status === 401) {
                        navigate('/');
                    }
                    console.log("Catch : " + error);
                    console.log("Catch : " + error.response.status);
                    console.log(error.response.data);
                    setFormError(error.response.data.message)
                    setError('error-block')

                });

        }
    }
    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="saccoBreeds">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label id="BreedNameError" className="bmd-label-floating">Animal Breed</label>
                                        <input type="text" className="form-control" id="BreedName" value={animalBreed} onChange={(e) => setItem(e.target.value)} />
                                        <small className="my-error">{errAnimalBreed}</small>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="btnAddBreed">Add Breed&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}