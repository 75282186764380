import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [spinner, setSpinier] = useState('');

    const [isLoading, setLoading] = useState(true);

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const [milkPrice, setMilkPrice] = useState('');
    const [milkLimit, setMilkLimit] = useState('');


    var saccoDairyPoint = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/getOpeningHours";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(saccoDairyPoint, {
            sacco: sacco,
            email: email,
            token: token,
            pointType: "AllPoint",
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                //setDairyPoints(response.data.data);
                console.log(response.data.data);
                setLoading(false);
                if (response.data.data.length === 0) {
                    setError('error-block')
                    setFormError("Sacco settings not set");
                } else {
                    setError('error');
                    setFormError('');
                    setMilkPrice(response.data.data[0].milkPrice);
                    setMilkLimit(response.data.data[0].milkLimit);
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log(error);
            });

    }, []);

    

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinier('fa fa-spinner fa-spin');

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/saveMilkSetting";

        axios.post(filterURL, {
            sacco: sacco,
            price: milkPrice,
            limit: milkLimit,
            email: email,
            token: token,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setSpinier('');
                setError('error')
                setMilkPrice('')
                setMilkLimit('');
                Swal.fire(
                    response.data.status,
                    response.data.data[0].msg,
                    'success'
                );
            })
            .catch(function (error) {
                setSpinier('');
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log("Catch : " + error);
                console.log("Catch : " + error.response.status);
                console.log(error.response.data);
                setFormError(error.response.data.message)
                setError('error-block')

            });

    }


    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="addDairyPoint">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="dairyPointName" id="DairyPointError" className="card-category">Milk Price</label>
                                        <input type="text" className="form-control" id="dairyPointName" value={milkPrice} onChange={(e) => setMilkPrice(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="dairyPointLocation" id="DairyPointError" className="card-category">Farmer Milk Delivery Times Limit Per Day</label>
                                        <input type="text" className="form-control" id="dairyPointLocation" value={milkLimit} onChange={(e) => setMilkLimit(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="btnAddPoint">Save&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}