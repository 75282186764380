import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddFarmerInseminationBreed from "./AddFarmerInseminationBreed";
import AddBreed from "./AddBreed";
import RemoveBreed from "./RemoveBreed";
import axios from "axios";

export default function () {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [isLoading, setLoading] = useState(true);

    const [animalBreeds, setSaccoInseminationBreeds] = useState([]);

    const [activeTab, setActiveTab] = useState(<AddFarmerInseminationBreed animalBreeds={animalBreeds} />);

    const [addFarmerInseminationTab, setAddFarmerInseminationTab] = useState('nav-link active');
    const [addBreedTab, setAddBreedTab] = useState('nav-link');
    const [deleteBreedTab, setDeleteBreedTab] = useState('nav-link');

    var inseminationBreeds = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/getInseminationBreeds";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };
    useEffect(() => {
        axios.post(inseminationBreeds, {
            sacco: sacco,
            email: email,
            token: token,
            state: "Active",
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setSaccoInseminationBreeds(response.data.data);
                setActiveTab(<AddFarmerInseminationBreed animalBreeds={response.data.data} />)
                console.log(response.data.data);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
            });

    }, []);


    const handleTab = (tab) => {
        // update the state to tab2
        if (tab === "AddFarmerInsemination") {
            setAddFarmerInseminationTab('nav-link active');
            setAddBreedTab('nav-link');
            setDeleteBreedTab('nav-link')
            setActiveTab(<AddFarmerInseminationBreed animalBreeds={animalBreeds} />);
        } else if (tab === "AddBreed") {
            setAddFarmerInseminationTab('nav-link');
            setAddBreedTab('nav-link active');
            setDeleteBreedTab('nav-link')
            setActiveTab(<AddBreed />);
        } else if (tab === "Delete") {
            setAddFarmerInseminationTab('nav-link');
            setAddBreedTab('nav-link');
            setDeleteBreedTab('nav-link active')
            setActiveTab(<RemoveBreed animalBreeds={animalBreeds} />);
        }

    };

    if (isLoading) {
        return <div></div>;
    }


    return (
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="card">
                    <div className="card-header card-header-tabs card-header-primary">
                        <div className="d-flex">
                            <span className="nav-tabs-title">ARTIFICIAL INSEMINATION</span>
                        </div>
                        <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                                <span className="nav-tabs-title">Tasks:</span>
                                <ul className="nav nav-tabs" data-tabs="tabs">
                                    <li className="nav-item">
                                        <button className={addFarmerInseminationTab} onClick={() => handleTab("AddFarmerInsemination")} data-toggle="tab">
                                            Add Farmer Insemination
                                            <div className="ripple-container"></div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={addBreedTab} onClick={() => handleTab("AddBreed")} data-toggle="tab">
                                            Add Animal Breed
                                            <div className="ripple-container"></div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={deleteBreedTab} onClick={() => handleTab("Delete")} data-toggle="tab">
                                            Update Animal Breed
                                            <div className="ripple-container"></div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {activeTab}

                </div>
            </div>
        </div>

    )
}