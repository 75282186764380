import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const capitalizeWord= (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
      }

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [formError, setFormError] =  useState('');
    const [error, setError] = useState('error');

    const [spinner, setSpinier] = useState('');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [category, setCategory] = useState(getInitialStateBranch);
    const [item, setItem] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [units, setUnit] = useState(getInitialStateBranch);
    const [quantity, setQuantity] = useState('');

    //set error messages
    const [errCategory, setErrCategoryMsg] = useState('');
    const [errItem, setErrItemsMsg] = useState('');
    const [errItemPrice, setErrPriceMsg] = useState('');
    const [errUnits, setErrUnitsMsg] = useState('');
    const [errQuantities, setErrQuantitiesMsg] = useState('');



    const onCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const onUnitChange = (e) => {
        setUnit(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (category === '' || category === null || units === 'select') {
            setErrCategoryMsg('Item category is required');
        } else {
            setErrCategoryMsg('');
        }

        if (item === '' || item === null) {
            setErrItemsMsg('Item is required');
        } else {
            setErrItemsMsg('');
        }

        if (itemPrice === '' || itemPrice === null || isNaN(itemPrice)) {
            setErrPriceMsg('Item price is required');
        } else {
            setErrPriceMsg('');
        }

        if (units === '' || units === null || units === 'select') {
            setErrUnitsMsg('Measuring unit is required');
        } else {
            setErrUnitsMsg('');
        }

        if (quantity === '' || quantity === null || isNaN(quantity)) {
            setErrQuantitiesMsg('Item quantity is required');
        } else {
            setErrQuantitiesMsg('');
        }

        if (category !== '' && category !== 'select' && item !== '' && !isNaN(itemPrice) && !isNaN(quantity) && units !== 'select' && units !== '') {

            setSpinier('fa fa-spinner fa-spin');

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addProduct";

        const headers = {
            'Authorization': `Bearer ${sessionID}`,
            "Content-Type": "application/json"
        };


        axios.post(filterURL, {
            sacco: sacco,
            item: capitalizeWord(item),
            quantity: quantity + " "+units,
            price: itemPrice,
            category: category,
            email: email,
            token: token,
            saccoBranchId: saccoBranchId

        }, { headers })
            .then(function (response) {
                setSpinier('');
                setCategory(getInitialStateBranch);
                setError('error');
                setFormError('');
                setItem('');
                setItemPrice('');
                Swal.fire(
                    response.data.status,
                    response.data.data[0].msg,
                    'success'
                  );
            })
            .catch(function (error) {
                setSpinier('');
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log("Catch : " + error);
                console.log("Catch : " + error.response.status);
                console.log(error.response.data);
                setFormError(error.response.data.message)
                setError('error-block')

            });

        } 
        // else {
        //     setFormError("please ensure the add product form is filled correctly")
        //     setError('error-block')
        // }
    }

    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="addProducts">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="item" id="itemError" className="bmd-label-floating">Item</label>
                                        <input type="text" className="form-control" id="item" value={item} onChange={(e) => setItem(e.target.value)}  />
                                        <small className="my-error">{errItem}</small>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="item" id="itemError" className="bmd-label-floating">Item Price</label>
                                        <input type="text" className="form-control" id="item" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)}  />
                                        <small className="my-error">{errItemPrice}</small>
                                    </div>
                                </div>

                                <div className="col-md-1">
                                    <div className="form-group">
                                        <label htmlFor="item" id="itemError" className="bmd-label-floating">Quantity</label>
                                        <input type="text" className="form-control" id="item" value={quantity} onChange={(e) => setQuantity(e.target.value)}  />
                                        <small className="my-error">{errQuantities}</small>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="item" id="itemError" className="bmd-label-floating">Units</label>
                                        <select id="itemCategory" className="form-control" value={units} onChange={onUnitChange}>
                                        <option  value= "select">Select quantity units</option>
                                        <option  value= "Kg">Kilograms</option>
                                        <option  value= "g">Grams</option>
                                        <option  value= "Liters">Liters</option>
                                        <option  value= "ml">Milli Liters</option>
                                      </select>
                                      <small className="my-error">{errUnits}</small>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                      <label htmlFor="itemCategory" id="itemCategoryError" className="bmd-label-floating">Item Category</label>
                                      <select id="itemCategory" className="form-control" value={category} onChange={onCategoryChange}>
                                        <option  value= "select">Select Item Category</option>
                                        <option  value= "SaleItem">Sale Item</option>
                                        <option  value= "ParticularItem">Particular Item</option>
                                      </select>
                                      <small className="my-error">{errCategory}</small>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="Additem">Add Item&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}