import React from "react";
import ForgotCode from "../components/authentication/ForgotPasswordCode";

const ForgotPasswordCode = () => {

    return (
    <div className="wrapper ">
        <ForgotCode />
    </div>
    )
}

export default ForgotPasswordCode