import React,  { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [formError, setFormError] =  useState('');
    const [error, setError] = useState('error');

    const [spinnerItem, setSpinierItem] = useState('');
    const [spinnerParticular, setSpinierParticular] = useState('');

    const [isLoading, setLoading] = useState(true);

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [saleItems, setSaleItems] = useState([]);
    const [particularItems, setParticularItems] = useState([]);

    const [saleItem, setSaleItem] = useState(getInitialStateBranch);
    const [particularItem, setParticularItem] = useState(getInitialStateBranch);

    const onParticularChanga = (e) => {
        setParticularItem(e.target.value);
    };

    const onSaleChange = (e) => {
        setSaleItem(e.target.value);
    };

    var saccoDairyPoint = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/getAllSaccoProducts";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(saccoDairyPoint, {
            sacco: sacco,
            email: email,
            token: token,
            saccoBranchId: saccoBranchId
        }, { headers })
            .then(function (response) {
                setSaleItems(response.data.saleItems);
                setParticularItems(response.data.particularItems);
                console.log(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log(error);
            });

    }, []);

    const handleRender = () => {
        setTimeout(() => {
            window.location.reload(false);
        }, 2000);
      }

    const handleSubmitSale = async (e) => {

        e.preventDefault();

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/removeSaccoProducts";

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                setSpinierItem('fa fa-spinner fa-spin');
                axios.post(filterURL, {
                    sacco: sacco,
                    email: email,
                    token: token,
                    item: saleItem,
                    cartegory: 'SaleItem',
                    saccoBranchId: saccoBranchId
                }, { headers })
                    .then(function (response) {
                        setSpinierItem('');
                        Swal.fire(
                            response.data.status,
                            response.data.data[0].msg,
                            'success'
                          );

                          handleRender();
                    })
                    .catch(function (error) {
                        setSpinierItem('');
                        if (error.response.status === 401) {
                            navigate('/');
                        }
                        console.log("Catch : " + error);
                        console.log("Catch : " + error.response.status);
                        console.log(error.response.data);
                        setFormError(error.response.data.message)
                        setError('error-block')
    
                    });
            }
          })
    }

    const handleSubmitParticular = async (e) => {

        e.preventDefault();

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/removeSaccoProducts";

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                setSpinierParticular('fa fa-spinner fa-spin');
                axios.post(filterURL, {
                    sacco: sacco,
            email: email,
            token: token,
                item: particularItem,
                cartegory: 'ParticularItem',
                saccoBranchId: saccoBranchId
                }, { headers })
                    .then(function (response) {
                        setSpinierParticular('');
                        Swal.fire(
                            response.data.status,
                            response.data.data[0].msg,
                            'success'
                          );

                          handleRender();
                    })
                    .catch(function (error) {
                        setSpinierParticular('');
                        if (error.response.status === 401) {
                            navigate('/');
                        }
                        console.log("Catch : " + error);
                        console.log("Catch : " + error.response.status);
                        console.log(error.response.data);
                        setFormError(error.response.data.message)
                        setError('error-block')
    
                    });
            }
          })
    }

    if (isLoading) {
        return <div></div>;
      }


    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="messages">
                    <div className="card-body">
                    <p className={error}>{formError}</p>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <p id="delItemError" className="card-category">Select Item Sale</p>
                                    <form onSubmit={handleSubmitSale} id="removeProducts">
                                        <select id="delItem" className="form-control" value={saleItem} onChange={onSaleChange} >
                                        <option value="select">Select Item</option>
                                        {
                                            saleItems.map((item, i) => (
                                                <option key={i} value={item.productId}>{item.product}</option>
                                            ))
                                        }
                                        </select>
                                        <button type="submit" className="btn btn-primary pull-right" id="Removeitem">Remove Sale Item&nbsp;<i className={spinnerItem}></i></button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <p id="delPartItemError" className="card-category">Select Item Particular</p>
                                    <form onSubmit={handleSubmitParticular} id="removeParticulars">
                                        <select id="delPartItem" className="form-control" value={particularItem} onChange={onParticularChanga} >
                                        <option value="select">Select Item</option>
                                        {
                                            particularItems.map((items, i) => (
                                                <option key={i} value={items.productId}>{items.product}</option>
                                            ))
                                        }
                                        </select>
                                        <button type="submit" className="btn btn-primary pull-right" id="RemovePartitem">Remove Particular Item&nbsp;<i className={spinnerParticular}></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}