import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [formError, setFormError] =  useState('');
    const [error, setError] = useState('error');

    const [spinner, setSpinier] = useState('');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const capitalizeWord = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
      }

      const startsWith = (str, word) => {
        return str.lastIndexOf(word, 0) === 0;
    }

      const cheackWord = (name) => {
       if(startsWith(name, sacco)){
        return name
       } else {
        return sacco+" "+name
       }
      }

    const [saccoBranchType, setSaccoBranchType] = useState(getInitialStateBranch);
    const [dairyPointName, setDairyPointName] = useState('');
    const [dairyPointLocation, setDairyPointLocation] = useState('');

    const onBranchTypeChange = (e) => {
        setSaccoBranchType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinier('fa fa-spinner fa-spin');

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/addDairyPoint";

        const headers = {
            'Authorization': `Bearer ${sessionID}`,
            "Content-Type": "application/json"
        };

            axios.post(filterURL, {
                saccoBranchType: saccoBranchType,
                dairyPointName: cheackWord(capitalizeWord(dairyPointName)),
                dairyPointLocation: capitalizeWord(dairyPointLocation),
                email: email,
                token: token,
                saccoCode: sacco,
                saccoBranchId: saccoBranchId
            }, { headers })
                .then(function (response) {
                    setError('error')
                    setDairyPointName('')
                    setDairyPointLocation('');
                    setSaccoBranchType(getInitialStateBranch);
                    setSpinier('');
                    Swal.fire(
                        response.data.status,
                        response.data.data[0].msg,
                        'success'
                      );
                })
                .catch(function (error) {
                    setSpinier('');
                    if (error.response.status === 401) {
                        navigate('/');
                    }
                    console.log("Catch : " + error);
                    console.log("Catch : " + error.response.status);
                    console.log(error.response.data);
                    setFormError(error.response.data.message)
                    setError('error-block')

                });
        
    }

    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                    <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="addDairyPoint">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="dairyPointName" id="DairyPointError" className="card-category">Dairy Point Name</label>
                                        <input type="text" className="form-control" id="dairyPointName" value={dairyPointName} onChange={(e) => setDairyPointName(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="dairyPointLocation" id="DairyPointError" className="card-category">Dairy Point Location</label>
                                        <input type="text" className="form-control" id="dairyPointLocation" value={dairyPointLocation} onChange={(e) => setDairyPointLocation(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <p id = "SelectTypeError" className="card-category">Dairy Point Type</p>
                                <select id="SelectType" className="form-control" value={saccoBranchType} onChange={onBranchTypeChange}>
                                <option  value="select">Select Dairy Point Type</option>
                                <option  value="Collection/SalePoint">Collection/Sale Point</option>
                                <option  value="SalePoint">Sale Point</option>
                                </select>
                                </div>
                            </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="btnAddPoint">Add Dairy Point&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}