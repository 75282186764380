import React, { useState, useEffect } from "react";
import LinkRejectedMilkMonthTable from "../viewRecords/LinkRejectedMilkMonthTable";
import LinkDairySalesTable from "../viewRecords/LinkDairySalesTable";

export default function ({yellowLongData}) {

    const [components, setComponents] = useState();

    useEffect(()=>{
        if(yellowLongData[3] === "RejectedMilk"){
                setComponents(<LinkRejectedMilkMonthTable />)
        } else if (yellowLongData[3] === "Particulars"){
            setComponents(<LinkDairySalesTable />);
        }
    });

    return (
        <div className="col-md-6">
            <div className="card card-chart">
                <div className="card-header card-header-danger">
                    <div className="ct-chart" id="completedTasksChart"></div>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{yellowLongData[0]}</h4>
                    <p className="card-category">{yellowLongData[1]}</p>
                    <h3 className="card-title">{yellowLongData[2]}</h3>
                </div>
                <div className="card-footer">
                    <div className="stats">
                    {components}
                        {/* <button id="btnMonthRejectedMilkExcel" type="submit" className="btn btn-sm btn-primary green"
                            name="Month-Rejected-Milk-Excel"><i id="monthRejected"></i> Download Excel file</button> */}
                    </div>
                </div>
            </div>
        </div>

    )
}