import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";

export default function ({ page }) {

    const navigate = useNavigate();

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const token = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const [spinner, setSpinier] = useState('');

    const [isLoading, setLoading] = useState(true);

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    //opening
    const [openningMonday, setOpeningMonday] = useState('');
    const [openningTusday, setOpeningTusday] = useState('');
    const [openningWenesday, setOpeningWenesday] = useState('');
    const [openningThursday, setOpeningThursday] = useState('');
    const [openningFriday, setOpeningFriday] = useState('');
    const [openningSaturday, setOpeningSaturday] = useState('');
    const [openningSunday, setOpeningSunday] = useState('');
    //closing
    const [closingMonday, setClosingMonday] = useState('');
    const [closingTusday, setClosingTusday] = useState('');
    const [closingWenesday, setClosingWenesday] = useState('');
    const [closingThursday, setClosingThursday] = useState('');
    const [closingFriday, setClosingFriday] = useState('');
    const [closingSaturday, setClosingSaturday] = useState('');
    const [closingSunday, setClosingSunday] = useState('');

    //errors
    const [errOpenningMonday, setErrOpenningMonday] = useState('');
    const [errOpenningTuesday, setErrOpeningTusday] = useState('');
    const [errOpenningWenesday, setErrOpeningWednesday] = useState('');
    const [errOpenningThursday, setErrOpeningThursday] = useState('');
    const [errOpenningFriday, setErrOpeningFriday] = useState('');
    const [errOpenningSaturday, setErrOpeningSaturday] = useState('');
    const [errOpenningSunday, setErrOpeningSunday] = useState('');
    const [errClosingMonday, setErrClosingMonday] = useState('');
    const [errClosingTuesday, setErrClosingTusday] = useState('');
    const [errClosingWenesday, setErrClosingWednesday] = useState('');
    const [errClosingThursday, setErrClosingThursday] = useState('');
    const [errClosingFriday, setErrClosingFriday] = useState('');
    const [errClosingSaturday, setErrClosingSaturday] = useState('');
    const [errClosingSunday, setErrClosingSunday] = useState('');


    var saccoDairyPoint = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/getOpeningHours";

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(saccoDairyPoint, {
            sacco: sacco,
            email: email,
            token: token,
            pointType: "AllPoint",
            saccoBranchId: saccoBranchId,
        }, { headers })
            .then(function (response) {
                //setDairyPoints(response.data.data);
                console.log(response.data.data);
                setLoading(false);
                if (response.data.data.length === 0) {
                    setError('error-block');
                    setFormError("Sacco operational hours not set");
                } else {
                    setError('error');
                    setFormError('');
                    setOpeningMonday(response.data.data[0].openMon);
                    setOpeningTusday(response.data.data[0].openTue);
                    setOpeningWenesday(response.data.data[0].openWen);
                    setOpeningThursday(response.data.data[0].openThur);
                    setOpeningFriday(response.data.data[0].openFri);
                    setOpeningSaturday(response.data.data[0].openSat);
                    setOpeningSunday(response.data.data[0].openSun);
                    setClosingMonday(response.data.data[0].closeMon);
                    setClosingTusday(response.data.data[0].closeTue);
                    setClosingWenesday(response.data.data[0].closeWen);
                    setClosingThursday(response.data.data[0].closeThur);
                    setClosingFriday(response.data.data[0].closeFri);
                    setClosingSaturday(response.data.data[0].closeSat);
                    setClosingSunday(response.data.data[0].closeSun);
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
                console.log(error);
            });

    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (openningMonday === "") {
            setErrOpenningMonday('Monday opening hour is required');
        } else {
            setErrOpenningMonday('');
        }

        if (openningTusday === "") {
            setErrOpeningTusday('Tusday opening hour is required');
        } else {
            setErrOpeningTusday('');
        }

        if (openningWenesday === "") {
            setErrOpeningWednesday('Wenesday opening hour is required');
        } else {
            setErrOpeningWednesday('');
        }

        if (openningThursday === "") {
            setErrOpeningThursday('Thursday opening hour is required');
        } else {
            setErrOpeningThursday('');
        }

        if (openningFriday === "") {
            setErrOpeningFriday('Friday opening hour is required');
        } else {
            setErrOpeningFriday('');
        }

        if (openningSaturday === "") {
            setErrOpeningSaturday('Saturday opening hour is required');
        } else {
            setErrOpeningSaturday('');
        }

        if (openningSunday === "") {
            setErrOpeningSunday('Sunday opening hour is required');
        } else {
            setErrOpeningSunday('');
        }

        //closing hours

        if (closingMonday === "") {
            setErrClosingMonday('Monday closing hour is required');
        } else {
            setErrClosingMonday('');
        }

        if (closingTusday === "") {
            setErrClosingTusday('Tusday closing hour is required');
        } else {
            setErrClosingTusday('');
        }

        if (closingWenesday === "") {
            setErrClosingWednesday('Wenesday closing hour is required');
        } else {
            setErrClosingWednesday('');
        }

        if (closingThursday === "") {
            setErrClosingThursday('Thursday closing hour is required');
        } else {
            setErrClosingThursday('');
        }

        if (closingFriday === "") {
            setErrClosingFriday('Friday closing hour is required');
        } else {
            setErrClosingFriday('');
        }

        if (closingSaturday === "") {
            setErrClosingSaturday('Saturday closing hour is required');
        } else {
            setErrClosingSaturday('');
        }

        if (closingSunday === "") {
            setErrClosingSunday('Sunday closing hour is required');
        } else {
            setErrClosingSunday('');
        }

        var filterURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/ManageDairySacco/openingHours";

        setSpinier('fa fa-spinner fa-spin');

        axios.post(filterURL, {
            sacco: sacco,
            email: email,
            token: token,
            monOpen: openningMonday,
            monClose: closingMonday,
            tueOpen: openningTusday,
            tueClose: closingTusday,
            wenOpen: openningWenesday,
            wenClose: closingWenesday,
            thuOpen: openningThursday,
            thuClose: closingThursday,
            friOpen: openningFriday,
            friClose: closingFriday,
            satOpen: openningSaturday,
            satClose: closingSaturday,
            sunOpen: openningSunday,
            sunClose: closingSunday,
            saccoBranchId: saccoBranchId

        }, { headers })
            .then(function (response) {
                setSpinier('');
                setError('error')
                setFormError('')
                //setItem('');
                Swal.fire(
                    response.data.status,
                    response.data.data[0].msg,
                    'success'
                );
            })
            .catch(function (error) {
                setSpinier('');
                console.log("Catch : " + error);
                console.log("Catch : " + error.response.status);
                console.log(error.response.data);
                setFormError(error.response.data.message)
                setError('error-block')

            });
    }

    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="card-body">
            <div className="tab-content">
                <div className="tab-pane active" id="profile">
                    <div className="card-body">
                        <p for="ToolPick" className={error}>{formError}</p>
                        <form onSubmit={handleSubmit} id="saccoBreeds">
                            <div className="row">
                                <div className="col-md-12">

                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Week Day</th>
                                                <th scope="col">Opening Hours</th>
                                                <th scope="col">Closing Hours</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Monday</th>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={openningMonday} onChange={(e) => setOpeningMonday(e.target.value)} />
                                                    <small className="my-error">{errOpenningMonday}</small>
                                                </td>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={closingMonday} onChange={(e) => setClosingMonday(e.target.value)} />
                                                    <small className="my-error">{errClosingMonday}</small>
                                                </td>
                                                <td>Open</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Tusday</th>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={openningTusday} onChange={(e) => setOpeningTusday(e.target.value)} />
                                                    <small className="my-error">{errOpenningTuesday}</small></td>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={closingTusday} onChange={(e) => setClosingTusday(e.target.value)} />
                                                    <small className="my-error">{errClosingTuesday}</small></td>
                                                <td>Open</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Wenesday</th>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={openningWenesday} onChange={(e) => setOpeningWenesday(e.target.value)} />
                                                    <small className="my-error">{errOpenningWenesday}</small>
                                                </td>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={closingWenesday} onChange={(e) => setClosingWenesday(e.target.value)} />
                                                    <small className="my-error">{errClosingWenesday}</small>
                                                </td>
                                                <td>Open</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Thursday</th>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={openningThursday} onChange={(e) => setOpeningThursday(e.target.value)} />
                                                    <small className="my-error">{errOpenningThursday}</small>
                                                </td>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={closingThursday} onChange={(e) => setClosingThursday(e.target.value)} />
                                                    <small className="my-error">{errClosingThursday}</small>
                                                </td>
                                                <td>Open</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Friday</th>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={openningFriday} onChange={(e) => setOpeningFriday(e.target.value)} />
                                                    <small className="my-error">{errOpenningFriday}</small>
                                                </td>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={closingFriday} onChange={(e) => setClosingFriday(e.target.value)} />
                                                    <small className="my-error">{errClosingFriday}</small>
                                                </td>
                                                <td>Open</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Satarday</th>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={openningSaturday} onChange={(e) => setOpeningSaturday(e.target.value)} />
                                                    <small className="my-error">{errOpenningSaturday}</small>
                                                </td>
                                                <td>
                                                    <input type="time" className="ms-4 form-control input-sm timepicker date-input" value={closingSaturday} onChange={(e) => setClosingSaturday(e.target.value)} />
                                                    <small className="my-error">{errClosingSaturday}</small>
                                                </td>
                                                <td>Open</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sunday</th>
                                                <td>
                                                    <input type="time" className="form-control input-sm timepicker date-input" value={openningSunday} onChange={(e) => setOpeningSunday(e.target.value)} />
                                                    <small className="my-error">{errOpenningSunday}</small>
                                                </td>
                                                <td>
                                                    <input type="time" className="form-control input-sm timepicker date-input" value={closingSunday} onChange={(e) => setClosingSunday(e.target.value)} />
                                                    <small className="my-error">{errClosingSunday}</small>
                                                </td>
                                                <td>Open</td>
                                            </tr>
                                        </tbody>
                                    </table>



                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary pull-right" id="btnAddBreed">Add/Update Operation Time&nbsp;<i className={spinner}></i></button>
                            <div className="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}