import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import FarmersMilkRecordTable from "../components/tables/FarmersMilkRecordTable";
import FarmerRejectedMilkRecordTable from "../components/tables/FarmerRejectedMilkRecordTable";
import FarmerAiRecordTable from "../components/tables/FarmerAiRecordTable";
import FarmerParticularRecordTable from "../components/tables/FarmerParticularRecordTable";
import Footer from "../components/footer/Footer";

import axios from "axios";
import Swal from 'sweetalert2';

const FarmersRecords = () => {

    const sessionID = localStorage.getItem("MilkCardSmsSessionId");
    const sacco = localStorage.getItem("MilkCardSmsDairySaccoCode");
    const tokenKey = localStorage.getItem("MilkCardSmsTokenKey");
    const email = localStorage.getItem("MilkCardSmsEmail");
    const firstName = localStorage.getItem("MilkCardSmsFirstName");
    const saccoBranchId = localStorage.getItem("MilkCardSmsBranchId");

    const navigate = useNavigate();

    const [components, setComponents] = useState([]);

    const [formError, setFormError] = useState('');
    const [error, setError] = useState('error');

    const getInitialStateBranch = () => {
        const value = "select";
        return value;
    };

    const [farmersNumber, setFarmersNumber] = useState('');
    const [date, setDate] = useState('');
    const [recordType, setRecordType] = useState(getInitialStateBranch);
    const [recordState, setRecordState] = useState(getInitialStateBranch);

    //set error messages
    const [errFarmerNumber, setErrFarmerNumberMsg] = useState('');
    const [errRecordType, setErrRecordTypeMsg] = useState('');
    const [errRecordState, setErrRecordStateMsg] = useState('');
    const [errDate, setErrDate] = useState('');

    useEffect(() => {
        setErrFarmerNumberMsg('');
    }, [farmersNumber]);

    useEffect(() => {
        setErrRecordTypeMsg('');
    }, [recordType]);

    useEffect(() => {
        setErrRecordStateMsg('');
    }, [recordState]);

    useEffect(() => {
        setErrDate('');
    }, [date]);

    const onRecordType = (e) => {
        setRecordType(e.target.value);
    };

    const onRecordState = (e) => {
        setRecordState(e.target.value);
    };

    var sessionURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/admin/session"

    const headers = {
        'Authorization': `Bearer ${sessionID}`,
        "Content-Type": "application/json"
    };

    useEffect(() => {
        axios.post(sessionURL, {
            sacco: sacco,
            email: email,
            token: tokenKey,
            saccoBranchId: saccoBranchId,
        }, { headers })
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    navigate('/');
                }
            });

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (farmersNumber === "" || farmersNumber === null || isNaN(farmersNumber)) {
            setErrFarmerNumberMsg("Farmer's number should be a number");
        } else {
            setErrFarmerNumberMsg("");
        }
        if (recordType === "select") {
            setErrRecordTypeMsg("Select a record Type")
        } else {
            setErrRecordTypeMsg("");
        }
        if (recordState === "select") {
            setErrRecordStateMsg("Select a record state")
        } else {
            setErrRecordStateMsg("");
        }
        if(date === ""){
            setErrDate("Record date is required");
        } else {
            setErrDate("");
        }
        console.log(farmersNumber, date, recordType, recordState)


        //milk record search

        if (farmersNumber !== null && farmersNumber !== "" && date !== "" && recordType !== "select" && recordState !== "select" && !isNaN(farmersNumber)) {

            var farmerRecordSearchURL = "https://debug.martinnganganjoroge.com/gateway/admin/Request/FarmersRecords/farmerSearchRecord";

            axios.post(farmerRecordSearchURL, {
                sacco: sacco,
                farmerNumber: sacco+"-"+farmersNumber,
                recordType: recordType,
                recordState: recordState,
                searchDate: date,
                email: email,
                token: tokenKey,
                saccoBranchId: saccoBranchId,
            }, { headers })
                .then(function (response) {
                    console.log(response.data);
                    if(recordType === "Collected"){
                        setComponents(<FarmersMilkRecordTable search={[response.data.data, response.data.sum, farmersNumber]} />)
                    } else if (recordType === "Rejected") {
                        setComponents(<FarmerRejectedMilkRecordTable search={[response.data.data, response.data.sum, farmersNumber]} />)
                    } else if (recordType === "Insemination") {
                        setComponents(<FarmerAiRecordTable search={[response.data.data, response.data.sum, farmersNumber]} />)
                    } else if(recordType === "Particulars"){
                        setComponents(<FarmerParticularRecordTable search={[response.data.data, response.data.sum, farmersNumber]}/>)
                    }

                    setError('error');
                    // setFarmersNumber('');
                    // setDate('');
                    // setRecordType(getInitialStateBranch);
                    // setRecordState(getInitialStateBranch)
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        navigate('/');
                    } else {
                        setFormError(error.response.data.message)
                        setError('error-block');
                    }
                        
                })
                .then(() => { });
        }

    }

    return (
        <div className="wrapper ">
            <SideBar pageActive={"saccoFarmers"} />
            <div className="main-panel">
                <NavBar page={["Farmers Records", firstName]} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title ">Search for Farmers Records</h4>
                                        <p className="card-category">You can search for farmers milk and particulars records or updating farmers details by selecting a record type</p>
                                    </div>
                                    <div className="card-body">
                                    <p for="ToolPick" className={error}>{formError}</p>
                                        <form onSubmit={handleSubmit} id="farmerRecordSearch">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="FarmersNumber" id="farNum" className="card-category">Farmers Number</label>
                                                        <input type="text" className="form-control" id="FarmersNumber" value={farmersNumber} onChange={(e) => setFarmersNumber(e.target.value)} />
                                                        <small className="my-error">{errFarmerNumber}</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="RecordType" className="card-category">Record Type</label>
                                                        <select id="RecordType" className="form-control" value={recordType} onChange={onRecordType}>
                                                            <option value="select">Select Record Type</option>
                                                            <option value="Collected">Collected Milk</option>
                                                            <option value="Rejected">Rejected Milk</option>
                                                            <option value="Particulars">Farmer Particulars</option>
                                                            <option value="Insemination">Artificial Insemination</option>
                                                            <option value="CashAdvance">Cash Advance</option>
                                                        </select>
                                                        <small className="my-error">{errRecordType}</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="RecordState" className="card-category">Record State</label>
                                                        <select id="RecordState" className="form-control" value={recordState} onChange={onRecordState}>
                                                            <option value="select">Select Record State</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">Disabled</option>
                                                        </select>
                                                        <small className="my-error">{errRecordState}</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="SearchDate" id="dateError" className="card-category">Date</label>
                                                        <input type="date" className="form-control" id="SearchDate" value={date} onChange={(e) => setDate(e.target.value)} />
                                                        <small className="my-error">{errDate}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" id="FarmerRecordSearch" className="btn btn-sm btn-primary"><i id="searchRecord"></i> Search Record</button>
                                            <div className="clearfix"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="search">
                            {components}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default FarmersRecords